import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import axios from 'axios'
import {
  Input,
} from '../../components'
import BootstrapButton from '../../assets/styles/bootstrapButton'
import cielChineLogo from '../../assets/images/logoCielChineHeader.png'
import cielChineLogo2x from '../../assets/images/logoCielChineHeader@2x.png'
import cielChineLogo3x from '../../assets/images/logoCielChineHeader@3x.png'
import stage from '../../config'

class ChangePassword extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.control = this.control.bind(this)
    this.error = this.error.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      password: '',
      newPassword: '',
      confirmedPassword: '',
      ctrlError: null,
    };
  }

  componentDidMount() {
    // Load all saved email in database
    // api.get_list_email_users()
  }

  goTo = () => {
    const { history } = this.props
    history.push('myAccount')
  }

  handleChange = field => (event) => {
    this.setState({ [field]: event.target.value });
  };

  control = () => {
    const {
      newPassword,
      confirmedPassword,
    } = this.state
    // matches any word character (equal to [a-zA-Z0-9_])
    const isPassword = /^\w{8,}$/g
    const ctrlPassword = isPassword.test(newPassword)
    // verify password conformity
    if (!ctrlPassword) {
      this.setState({
        ctrlError: 'password not conform',
      })
      return false
    }
    // verify confirmation password
    if (newPassword !== confirmedPassword) {
      this.setState({
        ctrlError: 'password not confirmed',
      })
      return false
    }
    this.setState({
      ctrlError: null,
    })
    const isValidForm = !!ctrlPassword
    return isValidForm
  }

  error = () => {
    const { ctrlError } = this.state
    return ctrlError ? <p style={{ color: 'red' }}>{ctrlError}</p> : null
  }

  sendForm = () => {
    event.preventDefault()
    const apiUrl = stage.api.duyen.url
    const {
      token,
      password,
      newPassword,
    } = this.state
    if (this.control()) {
      // post new user
      axios.post(`${apiUrl}/api/changePassword`, { password, newPassword }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            alert('Your password has been successfully modified')
            this.goTo()
          } else {
            return this.error()
          }
          return null
        })
        .catch(err => console.log(err))
    } else {
      this.error()
    }
  }

  render() {
    const {
      password,
      newPassword,
      confirmedPassword,
    } = this.state
    const {
      history,
    } = this.props
    return (
      <form className="signin" method="POST" onSubmit={this.sendForm}>
        <img
          className="logo"
          src={cielChineLogo}
          srcSet={`${cielChineLogo2x} 2x, ${cielChineLogo3x} 3x`}
          alt=""
          style={{ width: '200px', marginBottom: '30px' }}
        />
        {this.error()}
        <Input
          id="password"
          label="Current Password"
          value={password}
          type="password"
          className="inputWidth"
          onChange={this.handleChange('password')}
          margin="normal"
          required
        />
        <Input
          id="newPassword"
          label="New Password"
          value={newPassword}
          type="password"
          className="inputWidth"
          onChange={this.handleChange('newPassword')}
          margin="normal"
          required
        />
        <Input
          id="confirmPassword"
          label="Confirm Password"
          value={confirmedPassword}
          type="password"
          className="inputWidth"
          onChange={this.handleChange('confirmedPassword')}
          margin="normal"
          required
        />

        {/* validate button */}
        <BootstrapButton
          type="submit"
          variant="contained"
          color="primary"
          disableRipple
        >
          SEND
        </BootstrapButton>

        {/* Cancel button */}
        <div className="cancelChangePasswordDiv">
          <a
            href="#"
            // className={`button ${disabledClass} ${backgroundColor}`}
            type="button"
            // disabled={disabled}
            onClick={() => history.push('myAccount')}
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }
}

export default withRouter(ChangePassword)
