/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/accessible-emoji */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import HtmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import Jimp from 'jimp'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';

import stage from '../../../config'
import 'moment/locale/it';

import {
  ActionButton,
  Loader,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'


const savedImgUrl = stage.imgStorage

class GenerateHtmlCielProWord extends PureComponent {
  static propTypes = {
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripRoom: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    tripBilling: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    userAccount: PropTypes.object,
    accomodationList: PropTypes.array,
  }

  static defaultProps = {
    tripDetails: [],
    userAccount: {},
    tripAllDays: [],
    tripRoom: [],
    tripContent: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    accomodationList: [],

  }


  constructor(props) {
    super(props);
    this.getArrayPictures = this.getArrayPictures.bind(this)
    this.getSinglePicture = this.getSinglePicture.bind(this)
    this.getAllPictures = this.getAllPictures.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.resetMsg = this.resetMsg.bind(this)
    this.state = {
      isLoading: false,
      errorMsg: '',

    };
  }

  resetMsg =() => {
    setTimeout(() => {
      this.setState({
        errorMsg: null,
      })
    }, 6000)
  }


  Export2Word = (element, filename = '') => {
    const content = element;
    try {
      const docx = HtmlDocx.asBlob(content, { margins: { right: 720, left: 720 } });
      saveAs(docx, filename);
    } catch (error) {
      console.error('Error generating DOCX:', error);
    }
  }

   // Get array of images
   getArrayPictures = async (arrayData, width, height) => {
     const { companyContent } = this.props;
     const { company } = companyContent;

     if (_.isEmpty(arrayData)) return [];


     const getMimeTypeFromExtension = (filename) => {
       const ext = filename.split('.').pop().toLowerCase();
       const mimeTypes = {
         jpg: 'image/jpeg',
         jpeg: 'image/jpeg',
         png: 'image/png',
         gif: 'image/gif',
       };
       return mimeTypes[ext] || 'image/jpeg'; // Valeur par défaut
     };

     try {
       // Tableau de promesses pour chaque image
       const promises = _.flatMap(arrayData, (a, i) => {
         if (a.image && a.image !== '' && a.image !== 'default.png') {
           return a.image.split(',').map(async (img) => {
             const mimeType = getMimeTypeFromExtension(img);
             try {
               const response = await axios.get(`${savedImgUrl}/${company}/${img}`, {
                 headers: {
                   'Cache-Control': 'no-cache',
                   'Access-Control-Allow-Origin': '*',
                   'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                   'Access-Control-Allow-Headers': '*',
                 },
                 responseType: 'arraybuffer',
               });

               const prefix = `data:${mimeType};base64,`;
               const resizedBuffer = await this.resizeImage(response.data, width, height); // Redimensionner l'image en buffer

               const resizedBase64 = Buffer.from(resizedBuffer).toString('base64');
               return {
                 idDay: a.id_journee,
                 rank: i,
                 alignLeft: i % 2 === 0,
                 image: prefix + resizedBase64,
               };
             } catch (error) {
               console.error('Error fetching image:', error, img);
               const name = a.nom_journee ? a.nom_journee : a.nom_hebergement;
               this.setState(prevState => ({
                 errorMsg: `${prevState.errorMsg ? `${prevState.errorMsg}\n` : ''}1 - A query error has occurred for ${a.image} in ${name}. check this picture`,
               }));
               return null; // Retourne null si l'image échoue
             }
           });
         }
         return [];
       });

       // Attendre que toutes les promesses soient terminées
       const results = await Promise.all(promises);

       // Filtrer les éventuelles valeurs nulles (en cas d'erreur)
       return results.filter(result => result !== null);
     } catch (error) {
       console.error('Error during processing:', error);
       this.setState({
         errorMsg: '2 - A query error has occurred. Please retry',
         isLoading: false,
       });
       return [];
     }
   };


  // Get single image
  getSinglePicture = async (data, width, height) => {
    const { companyContent } = this.props
    const { company } = companyContent
    if (!!data && data !== '' && data !== 'default.png') {
      try {
        const getMimeTypeFromExtension = (filename) => {
          const ext = filename.split('.').pop().toLowerCase();
          const mimeTypes = {
            jpg: 'image/jpeg',
            jpeg: 'image/jpeg',
            png: 'image/png',
            gif: 'image/gif',
          };
          return mimeTypes[ext] || 'image/jpeg'; // Par défaut
        };

        const mimeType = getMimeTypeFromExtension(data);
        const imgBase64 = axios.get(`${savedImgUrl}/${company}/${data}`, {
          headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers': '*',
          },
          responseType: 'arraybuffer',
        })
          .then(async (response) => {
            const resizedBuffer = await this.resizeImage(response.data, width, height); // Redimensionner l'image en buffer

            const prefix = `data:${mimeType};base64,`
            const resizedBase64 = Buffer.from(resizedBuffer).toString('base64');

            return prefix + resizedBase64;
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('Error 3 : ', error)
            this.setState(prevState => ({
              errorMsg: `${prevState.errorMsg ? `${prevState.errorMsg}\n` : ''}3 - A query error has occurred for ${data}. Please check this picture and retry`,
            }));
          })
        return imgBase64 || ''
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('image error', error)
        this.setState({
          errorMsg: '4 - A query error has occurred. Please retry',
          isLoading: false,
        })
      }
    }
    return ''
  }

  // Fonction de redimensionnement qui prend directement un buffer
resizeImage = (buffer, width, height) => new Promise((resolve, reject) => {
  Jimp.read(buffer)
    .then((image) => {
      image.resize(width, height); // Redimensionner l'image
      image.getBuffer(Jimp.MIME_PNG, (err, resizedBuffer) => {
        if (err) {
          reject(err);
        } else {
          resolve(resizedBuffer);
        }
      });
    })
    .catch((err) => {
      reject(err);
    });
});

   getAllPictures = async () => {
     const {
       tripAllDays,
       tripDetails,
       tripAccomodation,
       tripAccomodationOpt1,
       tripAccomodationOpt2,
     } = this.props;


     const {
       image,
       cover_image,
     } = tripDetails[0];

     this.setState({
       isLoading: true,
     });


     const imageArray = cover_image.split(',');
     // filter accomodation type 'Jonque'
     let daysPictureDataBase64 = ['init'];
     let tripAccomodationBase64 = ['init'];
     let tripAccomodationOpt1Base64 = ['init'];
     let tripAccomodationOpt2Base64 = ['init'];
     let mapDataBase64 = 'init';
     let coverBase64 = 'init';
     let licence64 = 'init'
     try {
       mapDataBase64 = tripDetails && image ? await this.getSinglePicture(image, 733, 508) : 'no data'
       coverBase64 = tripDetails && cover_image ? await this.getSinglePicture(imageArray[0], 733, 508) : 'no data'
       daysPictureDataBase64 = tripAllDays ? await this.getArrayPictures(tripAllDays, 360, 250) : ['no data']
       tripAccomodationBase64 = tripAccomodation ? await this.getArrayPictures(tripAccomodation, 240, 180) : ['no data']
       tripAccomodationOpt1Base64 = tripAccomodationOpt1 ? await this.getArrayPictures(tripAccomodationOpt1, 240, 180) : ['no data']
       tripAccomodationOpt2Base64 = tripAccomodationOpt2 ? await this.getArrayPictures(tripAccomodationOpt2, 240, 180) : ['no data']
       licence64 = await this.getSinglePicture('license-1689587893848.jpg', 500, 500)
     } catch (error) {
       // eslint-disable-next-line no-console
       console.log('Error 5 : ', error);
       this.setState({
         errorMsg: '5 - A query error has occurred. Please retry',
         isLoading: false,
       })
     }

     if (
       (mapDataBase64 !== 'init')
      && (coverBase64 !== 'init')
      && (daysPictureDataBase64[0] !== 'init')
      && (tripAccomodationBase64[0] !== 'init')
      && (tripAccomodationOpt1Base64[0] !== 'init')
      && (tripAccomodationOpt2Base64[0] !== 'init')
      && (licence64 !== 'init')

     ) {
       // Appeler generateDoc uniquement lorsque tout est prêt
       this.generateDoc(
         daysPictureDataBase64,
         tripAccomodationBase64,
         tripAccomodationOpt1Base64,
         tripAccomodationOpt2Base64,
         mapDataBase64,
         coverBase64,
         licence64,
       );
       this.setState({ isLoading: false });
       this.resetMsg()
     }

     // Adding a timeout to allow time to retrieve all the images


     return null
   }


   myPageFunc = (daysPictureDataBase64, tripAccomodationBase64, tripAccomodationOpt1Base64, tripAccomodationOpt2Base64, mapDataBase64, coverBase64, licence64) => {
     const tableauHebergement = (roomAccoArray, roomAcco1Array, roomAcco2Array) => {
       const {
         accomodationList,
         tripAllDays,
       } = this.props
       return (roomAccoArray.map((item, i) => {
         const hebTrouve = parseInt(roomAccoArray[i], 10) === 1 ? 'none' : (

           accomodationList.find(objet => objet.id_hebergement === parseInt(roomAccoArray[i], 10))
         )
         const hebTrouve1 = parseInt(roomAcco1Array[i], 10) === 1 ? 'none' : (

           accomodationList.find(objet => objet.id_hebergement === parseInt(roomAcco1Array[i], 10))
         )
         const hebTrouve2 = parseInt(roomAcco2Array[i], 10) === 1 ? 'none' : (

           accomodationList.find(objet => objet.id_hebergement === parseInt(roomAcco2Array[i], 10))
         )
         return (
           {
             lieu_fin_journee: tripAllDays[i].lieu_fin_journee,
             nomHeb: hebTrouve === 'none' ? 'none' : hebTrouve.nom_hebergement,
             nomHeb1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.nom_hebergement,
             nomHeb2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.nom_hebergement,
             adresse: hebTrouve === 'none' ? 'none' : hebTrouve.adresse,
             adresse1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.adresse,
             adresse2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.adresse,
             desc_hebergement: hebTrouve === 'none' ? 'none' : hebTrouve.fr_desc_hebergement,
             desc_hebergement1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.fr_desc_hebergement,
             desc_hebergement2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.fr_desc_hebergement,
             heb: roomAccoArray[i],
             heb1: roomAcco1Array[i],
             heb2: roomAcco2Array[i],
             indexDayHeb: hebTrouve === 'none' ? 'none' : i,
             indexDayHeb1: hebTrouve1 === 'none' ? 'none' : i,
             indexDayHeb2: hebTrouve2 === 'none' ? 'none' : i,
             day_duration: tripAllDays[i].day_duration,
             cle: `${roomAccoArray[i]}${roomAcco1Array[i]}${roomAcco2Array[i]}${tripAllDays[i].lieu_fin_journee}`,
           }

         )
       })
       )
     }

     const findOcc = (arr, key) => {
       const arr2 = [];

       arr.forEach((x) => {
         // Checking if there is any object in arr2
         // which contains the key value
         if (arr2.some(val => val[key] === x[key])) {
           // If yes! then increase the occurrence by 1
           arr2.forEach((k) => {
             if (k[key] === x[key]) {
               // eslint-disable-next-line no-plusplus
               k.occurrence += x.day_duration
             }
           })
         } else {
           // If not! Then create a new object initialize
           // it with the present iteration key's value and
           // set the occurrence to 1
           const a = {}
           a[key] = x[key]
           a.occurrence = x.day_duration
           arr2.push(a);
         }
       })
       return arr2
     }

     const {
       tripDetails,
       userAccount,
       tripContent,
       tripAllDays,
       tripAccomodation,
       tripAccomodationOpt1,
       tripPension,
       tripBilling,
       informationsContent,
       locationList,
       tripRoom,
     } = this.props

     const {
       guide,
       chauffeur,
       nb_chambre,
       type_chambre,
       hebergement,
       hebergement_opt1,
       hebergement_opt2,
     } = tripContent[0]

     const {
       langage,
       prenom_responsable_groupe,
       nom_responsable_groupe,
       map_localisation,
       centre_interet,
       desc_voyage,
     } = tripDetails[0]
     const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
     const guideArray = _.split(guide, ',')
     const remarque = `${langage}_remarque`
     const roomTypeArray = _.split(type_chambre, ',')
     const roomAccoArray = _.split(hebergement, ',')
     const roomAcco1Array = _.split(hebergement_opt1, ',')
     const roomAcco2Array = _.split(hebergement_opt2, ',')
     const hasDifferentValueAcco1 = roomAcco1Array.some(value => value !== '1');
     const hasDifferentValueAcco2 = roomAcco2Array.some(value => value !== '1');
     const tableauUnique = tableauHebergement(roomAccoArray, roomAcco1Array, roomAcco2Array)
     const uniqueHotels = {};
     const originalOrder = [];
     tableauUnique.forEach((hotel, index) => {
       // Vérifier si l'email de l'hôtel existe dans les hôtels uniques
       if (hotel.cle && !uniqueHotels[hotel.cle]) {
         // Ajouter l'hôtel unique à l'objet uniqueHotels avec le jour de la réservation
         uniqueHotels[hotel.cle] = {
           ...hotel,
           reservedDays: [index + 1], // Ajoutez le jour de la réservation
         };
         originalOrder.push(index);
       } else if (hotel.cle && uniqueHotels[hotel.cle]) {
         // Si l'email existe déjà, ajoutez le jour de la réservation à l'array reservedDays
         uniqueHotels[hotel.cle].reservedDays.push(index + 1);
       }
     });
     const Hotel_Unique = originalOrder.map(index => uniqueHotels[tableauUnique[index].cle]);
     const Nombre_Occurence_Hotel = findOcc(tableauUnique, 'cle')
     const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
     const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
     const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
     let markerMap = ''
     tabMap.map((item, index) => map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))

     const TabIncJour = tripAllDays.map(item => (item.day_duration))
     const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
     const longueurVoyage = tripAllDays.length
     const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
     const firstname = prenom_responsable_groupe || null
     const lastname = nom_responsable_groupe || null
     const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
     const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).format('DD MMMM YYYY')
     const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDayTot - 1, 'd').locale(langage).format('DD MMMM YYYY')
     const chauffeurArray = _.split(chauffeur, ',')
     const texteEntreeSite = langage && `${langage}_information_5_description`;
     const texteHotel = langage && `${langage}_invoice`;

     const borderRadius = {
       borderRadius: 15,
     };
     const Fonddefaut = {
       backgroundColor: informationsContent.main_color,
     };

     const Colordefaut = {
       color: informationsContent.main_color,
     };

     const check = {
       fontSize: '12.0pt; mso-bidi-font-size: 10.5pt',
       fontFamily: 'MS Mincho',
       msoFareastFontFamily: 'DengXian',
       msoFareastThemeFont: 'minor-fareast',
       msoBidiFontFamily: 'MS Mincho',
       color: '#00b050',
       background: 'white',
       msoAnsiLanguage: 'FR',
       msoFaBidiLanguage: 'AR-SA',
     }


     const styleTabItiWord = {
       color: 'black',
       textAlign: 'left',
       paddingTop: '2px',
       paddingBottom: '2px',
       textDecoration: 'none !important',
     }

     const styleImageWord = {
       textAlign: 'center',
     }

     const stylePoppins = {
       fontWeight: 'bold',
       fontFamily: 'Poppins',
     }

     const stylePoppinsVet = {
       color: '#008000',
       fontSize: '12pt',
       fontWeight: 'bold',
       fontFamily: 'Poppins',
     }


     const nbOfRoomLabel = (index) => {
       const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
       const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
       const roomsNameForDay = roomsTypeForDay.map(rT => (tripRoom[index] && !_.isEmpty(tripRoom[index]) ? tripRoom[index].find(r => r.id_heb_ch === parseInt(rT, 10)).type_chambre : 'none'))
       const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j + 1 ? '' : ', '}`)
       return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
     }

     const myPage = (
       <div>
         <div className="GlobalFileSense">

           <Grid container spacing={0} align="row" justifyContent="center">
             <Grid item xs={12}>
               <div className="tabContainerCoverSense">
                 <table
                   className="tableauCover"
                   width="100%"
                 >
                   <tbody>
                     <tr>
                       <td className="container-cover" colSpan="2" width="100%">
                         <div className="cover-base">
                           <table
                             width="100%"
                           >
                             <tbody>
                               <tr>
                                 <td colSpan="3">
                                   <div
                                     className="container-cover1"
                                   >
                                     <img
                                       src={coverBase64}
                                     />
                                   </div>
                                 </td>
                               </tr>
                               <br />
                             </tbody>
                           </table>
                         </div>
                       </td>
                     </tr>
                     <tr>
                       <td width="100%">
                         <div className="titreCover1" style={Colordefaut}>{tripDetails[0].nom_programme}</div>
                         <div className="ligneTitreCielUniq" style={Fonddefaut} />
                         <div className="fontPresentation">
                           <div> PARTICIPANTS: {travelerName} </div>
                           <br />
                           <div>Date: Du {dateDepart} au {dateFin} </div>
                           <br />
                           <div>Durée :{IncDayTot} jours {tripDetails[0].destination}</div>
                           <br />
                           <div> Programme créé par  {advisorName} de CielChine.com </div>
                           <br />
                         </div>
                       </td>
                     </tr>
                     <br />
                   </tbody>
                 </table>
               </div>
             </Grid>
           </Grid>
         </div>
         <div className="sautePage" />
         <div className="CoupePasSense">
           <div id="brief" className="titreSense2" style={Colordefaut}>Itinéraire en bref</div>
           <div className="mettreRouge">
             <table
               className="tabItineraireSenseGlobal"
             >
               <tbody>
                 <tr>
                   <th width="20%" style={Fonddefaut}>Jour & date </th>
                   <th width="30%" style={Fonddefaut}>Programme</th>
                   <th width="18%" style={Fonddefaut}>Transport</th>
                   <th width="8%" style={Fonddefaut}>Repas</th>
                   <th width="4%" style={Fonddefaut}>Guide</th>
                   <th width="20%" style={Fonddefaut}>Hébergement</th>
                 </tr>
                 {tripAllDays.map((item, index) => {
                   const IncDay = _.sum(TabIncJour.slice(0, index))
                   const datejour = tripDetails[0].date_depart
                     ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                     : ''
                   let guideNeed = false
                   switch (guideArray[index]) {
                     case '0':
                       guideNeed = false
                       break;
                     case '1':
                       guideNeed = true
                       break;
                     case '2':
                       guideNeed = true
                       break;
                     case '3':
                       guideNeed = true
                       break;
                     case '4':
                       guideNeed = true
                       break;
                     default:
                       guideNeed = false
                   }
                   return (
                     <tr key={`Bref${item.id_journee}`}>
                       <td width="20%">
                         <a style={styleTabItiWord} href={`#Jour${index}`}>
                           J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} to ${IncDay + TabIncJour[index]}`)} {datejour}
                         </a>
                       </td>
                       <td width="30%">
                         <a style={styleTabItiWord} href={`#Jour${index}`}>
                           {item.nom_journee}
                         </a>
                       </td>
                       <td width="18%">
                         <a style={styleTabItiWord} href={`#Jour${index}`}>
                           {item.transport}
                         </a>
                       </td>
                       <td width="8%">
                         <a style={styleTabItiWord} href={`#Jour${index}`}>
                           {tripPension[index].pension !== 'none' && tripPension[index].pension}
                         </a>
                       </td>
                       <td width="4%">
                         <a style={styleTabItiWord} href={`#Jour${index}`}>
                           {guideNeed ? <span style={check}>✓</span> : ''}
                         </a>
                       </td>
                       <td width="10%">
                         <a style={styleTabItiWord} href={`#Jour${index}`}>
                           {tripAccomodation[index].nom_hebergement}
                         </a>
                       </td>
                     </tr>
                   )
                 })}
               </tbody>
             </table>
           </div>
         </div>
         <br />
         <br />
         <div className="titreSense2" style={Colordefaut}>Description du voyage</div>
         <Interweave content={desc_voyage} />
         <br />
         <div className="CoupePasSense">

           {(tripDetails[0].image || tripDetails[0].map_localisation) && (

           <div className="CoupePasSense">
             {tripDetails[0].image
                   && (
                     <div className="centreTabPrice" id="carte">
                       <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                       <br />
                       <img className="carte" style={borderRadius} src={mapDataBase64} />
                       <div className="sautePage" />
                     </div>
                   )}
           </div>
           )}
         </div>
         <div id="detail" className="titreSense2" style={Colordefaut}>Programme</div>
         <br />
         <div>
           {tripAllDays.map((item_jour, index) => {
             const desc = `desc_jour_${index + 1}`;
             const IncDay = _.sum(TabIncJour.slice(0, index))
             const imageArrayDay64 = daysPictureDataBase64 && daysPictureDataBase64.filter(dPDB => dPDB.rank === index)
             const datejour = tripDetails[0].date_depart
               ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
               : ''
             // eslint-disable-next-line no-nested-ternary
             let guideNeed = ''
             switch (guideArray[index]) {
               case '0':
                 guideNeed = ''
                 break;
               case '1':
                 guideNeed = langageTranslation.withGuide
                 break;
               case '2':
                 guideNeed = langageTranslation.withGuideEn
                 break;
               case '3':
                 guideNeed = langageTranslation.withGuideEnEvasion
                 break;
               case '4':
                 guideNeed = langageTranslation.withLocal
                 break;
               default:
                 guideNeed = ''
             }
             let driverNeed = ''
             switch (chauffeurArray[index]) {
               case '0':
                 driverNeed = ''
                 break;
               case '1':
                 driverNeed = langageTranslation.driverEvasion
                 break;
               case '2':
                 driverNeed = ''
                 break;
               case '3':
                 driverNeed = 'Transport Locaux'
                 break;
               case '4':
                 driverNeed = langageTranslation.busEvasion
                 break;
               case '5':
                 driverNeed = langageTranslation.driverSpeakingEnglish
                 break;
               case '6':
                 driverNeed = langageTranslation.driverSpeakingFrench
                 break;
               default:
                 driverNeed = ''
             }
             const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
             const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver

             const BandeauJour = () => (
               <div className="titreJournee">
                 <div id={`Jour${index}`}>  <span style={Colordefaut}>J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} - {datejour}:</span> {item_jour.nom_journee}  </div>
               </div>
             );

             const TableService = () => (
               <div className="marginDay">
                 {tripPension[index].pension !== 'none' && (<div className="fontAcco">Repas: {tripPension[index].pension}</div>)}
                 {(tripAccomodation && tripAccomodation[index].nom_hebergement !== 'none')
                                                  && (
                                                  <div style={stylePoppinsVet}>
                                                    {tripAccomodationOpt1 && tripAccomodationOpt1[index].nom_hebergement !== 'none' && 'Opt 1: '} Nuit en {tripAccomodation[index].nom_hebergement !== 'none' && (`${tripAccomodation[index].nom_hebergement}${!_.isEmpty(tripAccomodation[index].categorie) ? `(${tripAccomodation[index].categorie})` : ''}`)}
                                                    {nbOfRoomLabel(index) && (!roomsNumberArray ? '' : `, ${nbOfRoomLabel(index)}`)}
                                                  </div>
                                                  )
                      }
                 <div style={stylePoppins}>{asteriskText}</div>
                 {item_jour.distance && (<div style={stylePoppins}>{item_jour.distance}</div>)}
               </div>
             );
             return (
               <div className="centreTabPrice" key={`${datejour}`}>
                 <div>
                   <div>
                     <div>

                       <BandeauJour />
                       <div className="marginDay">
                         <Interweave content={tripContent[0][desc]} />
                       </div>
                       <div className="paddingService">
                         <TableService />
                       </div>

                       {imageArrayDay64 && !_.isEmpty(imageArrayDay64) && imageArrayDay64.length === 1
                        && (
                          <div style={styleImageWord}>
                            <img
                              src={imageArrayDay64 && !_.isEmpty(imageArrayDay64) && imageArrayDay64[0].image}
                            />
                          </div>

                        )}
                       {imageArrayDay64 && !_.isEmpty(imageArrayDay64) && imageArrayDay64.length === 2
                        && (
                          <table
                            style={{
                              width: '100%',
                            }}
                          >
                            <tr>
                              {imageArrayDay64.map(item => (
                                <td style={{
                                  width: '50%',
                                }}
                                >
                                  <div style={styleImageWord}>
                                    <img
                                      src={item.image}
                                    />
                                  </div>
                                </td>
                              ))}
                            </tr>
                          </table>
                        )}
                       {imageArrayDay64 && !_.isEmpty(imageArrayDay64) && imageArrayDay64.length > 2
                        && (
                          <table
                            style={{
                              width: '100%',
                            }}
                          >
                            <tr>
                              {imageArrayDay64.map(item => (
                                <td style={{
                                  width: '33%',
                                }}
                                >
                                  <div style={styleImageWord}>
                                    <img
                                      src={item.image}
                                    />
                                  </div>
                                </td>
                              ))}
                            </tr>
                          </table>
                        )}

                     </div>
                   </div>
                 </div>
               </div>
             )
           })}
         </div>
         <br />
         <div id="prix" className="titreSense2" style={Colordefaut}>Prix du voyage</div>
         <br />
         <div className="CoupePasSense">
           <div>
             <Interweave content={tripBilling.tabPrice} />
           </div>
           <br />
         </div>
         <div>
           <br />
           <div className="CoupePasSense">
             <div className="TitrePrixComprend">  {langageTranslation.priceContained}:  </div>
             <br />
             <Interweave content={tripDetails[0].prix_comprend} />
           </div>
           <div className="CoupePasSense">
             <div className="TitrePrixComprendPas">  {langageTranslation.priceDoNotContained}:  </div>
             <br />
             <Interweave content={tripDetails[0].prix_comprend_pas} />
           </div>
         </div>
         <br />
         <div id="detail" className="titreSense2" style={Colordefaut}>Entrée des sites</div>
         <br />
         <Interweave content={informationsContent[texteEntreeSite]} />
         <Interweave content={centre_interet} />
         <br />
         <div id="hotel" className="titreSense2" style={Colordefaut}>Hôtels</div>
         <br />
         <Interweave content={informationsContent[texteHotel]} />
         <br />
         <div>
           {parseInt(roomAcco1Array[0], 10) !== 1 && (
           <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 1</div>
           )}
           {
                    Hotel_Unique && Hotel_Unique.map((item, i) => {
                      const imageOfDay = tripAccomodationBase64 ? tripAccomodationBase64.filter(tAB => tAB.rank === item.indexDayHeb) : []
                      const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                      const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');

                      return (
                        <div key={`Acco${item.id_journee}`}>
                          {item.nomHeb !== 'none'
                    && (
                      <div className="CoupePasCiel">
                        <div>
                          <div className="afficheHotelEvasion">
                            <div className="CentreEvasion">
                              <div className="titreJournee">
                                <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb}</span> -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                              </div>
                              <table
                                style={{
                                  overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                                }}
                              >
                                <tr>
                                  <td>
                                    <Interweave content={item.desc_hebergement} />
                                    {item.adresse && !_.isEmpty(item.adresse) && <Interweave content={adresseHebClean} />}
                                  </td>
                                </tr>
                              </table>
                              {imageOfDay && imageOfDay[0] !== ''
                    && (
                      <div>
                        {imageOfDay && !_.isEmpty(imageOfDay) && imageOfDay[0].image !== '' && imageOfDay.length <= 2
    && (
    <table
      style={{
        width: '100%',
      }}
    >
      <tr>
        {imageOfDay && imageOfDay.map((itemHotel, indexImage) => (
          <td style={{
            width: '50%',
          }}
          >
            <div style={styleImageWord}>
              <img src={imageOfDay[indexImage].image} />
            </div>
          </td>
        ))}
      </tr>
    </table>
    )}
                        {imageOfDay && !_.isEmpty(imageOfDay) && imageOfDay.length > 2
    && (
      <table
        style={{
          width: '100%',
        }}
      >
        <tr>
          {imageOfDay && imageOfDay.map((itemHotel, indexImage) => (
            <td style={{
              width: '33%',
            }}
            >
              <div style={styleImageWord}>
                <img src={imageOfDay[indexImage].image} />
              </div>
            </td>
          ))}
        </tr>
      </table>
    )}
                      </div>
                    )}
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    )}
                        </div>
                      )
                    })
                   }
           {hasDifferentValueAcco1 && (
           <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 2</div>
           )}
           {hasDifferentValueAcco1 && (
             Hotel_Unique && Hotel_Unique.map((item, i) => {
               const imageOfDay = tripAccomodationOpt1Base64 ? tripAccomodationOpt1Base64.filter(tAB => tAB.rank === item.indexDayHeb1) : []
               const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
               const adresseHebClean = item.adresse1.replace(/<p>/g, '').replace(/<\/p>/g, '');
               return (
                 <div key={`Acco${item.id_journee}`}>
                   {item.nomHeb1 !== 'none'
                  && (
                    <div className="CoupePasCiel">
                      <div>
                        <div className="afficheHotelEvasion">
                          <div className="CentreEvasion">
                            <div className="titreJournee">
                              <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb1}</span> -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                            </div>
                            <table
                              style={{
                                overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                              }}
                            >
                              <tr>
                                <td>
                                  <Interweave content={item.desc_hebergement1} />
                                  {item.adresse1 && !_.isEmpty(item.adresse1) && <Interweave content={adresseHebClean} />}
                                </td>
                              </tr>
                            </table>
                            {imageOfDay[0] !== ''
                  && (

                    <div>
                      {imageOfDay[0] !== '' && !_.isEmpty(imageOfDay) && imageOfDay.length <= 2
  && (
    <table
      style={{
        width: '100%',
      }}
    >
      <tr>
        {imageOfDay && imageOfDay.map((itemHotel, indexImage) => (
          <td style={{
            width: '50%',
          }}
          >
            <div style={styleImageWord}>
              <img src={imageOfDay[indexImage].image} />
            </div>
          </td>
        ))}
      </tr>
    </table>
  )}
                      {imageOfDay[0] !== '' && imageOfDay.length > 2
  && (
    <table
      style={{
        width: '100%',
      }}
    >
      <tr>
        {imageOfDay && imageOfDay.map((itemHotel, indexImage) => (
          <td style={{
            width: '33%',
          }}
          >
            <div style={styleImageWord}>
              <img src={imageOfDay[indexImage].image} />
            </div>
          </td>
        ))}
      </tr>
    </table>
  )}
                    </div>
                  )}
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  )}
                 </div>
               )
             })
           )}
           {hasDifferentValueAcco2 && (
           <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 3</div>
           )}
           {hasDifferentValueAcco2 && (
             Hotel_Unique && Hotel_Unique.map((item, i) => {
               const imageOfDay = tripAccomodationOpt2Base64 ? tripAccomodationOpt2Base64.filter(tAB => tAB.rank === item.indexDayHeb2) : []
               const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
               const adresseHebClean = item.adresse2.replace(/<p>/g, '').replace(/<\/p>/g, '');

               return (
                 <div key={`Acco${item.id_journee}`}>
                   {item.nomHeb2 !== 'none'
                  && (
                    <div className="CoupePasCiel">
                      <div>
                        <div className="afficheHotelEvasion">
                          <div className="CentreEvasion">
                            <div className="titreJournee">
                              <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb2}</span> -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                            </div>
                            <table
                              style={{
                                overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                              }}
                            >
                              <tr>
                                <td>
                                  <Interweave content={item.desc_hebergement2} />
                                  {item.adresse2 && !_.isEmpty(item.adresse2) && <Interweave content={adresseHebClean} />}
                                </td>
                              </tr>
                            </table>
                            {imageOfDay[0] !== ''
                  && (
                    <div>
                      {imageOfDay[0] !== '' && !_.isEmpty(imageOfDay) && imageOfDay.length <= 2
  && (
    <table
      style={{
        width: '100%',
      }}
    >
      <tr>
        {imageOfDay && imageOfDay.map((itemHotel, indexImage) => (
          <td style={{
            width: '50%',
          }}
          >
            <div style={styleImageWord}>
              <img src={imageOfDay[indexImage].image} />
            </div>
          </td>
        ))}
      </tr>
    </table>
  )}
                      {imageOfDay[0] !== '' && imageOfDay.length > 2
  && (
    <table
      style={{
        width: '100%',
      }}
    >
      <tr>
        {imageOfDay && imageOfDay.map((itemHotel, indexImage) => (
          <td style={{
            width: '33%',
          }}
          >
            <div style={styleImageWord}>
              <img src={imageOfDay[indexImage].image} />
            </div>
          </td>
        ))}
      </tr>
    </table>
  )}
                    </div>
                  )}
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  )}
                 </div>
               )
             })
           ) }

         </div>
         <div id="info" className="centreTabPrice">
           <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
         </div>
         <div style={styleImageWord}>
           <img
             src={licence64}
           />
         </div>

       </div>

     )
     return myPage
   }

   generateDoc(daysPictureDataBase64, tripAccomodationBase64, tripAccomodationOpt1Base64, tripAccomodationOpt2Base64, mapDataBase64, coverBase64, licence64) {
     const {
       tripDetails,
     } = this.props;

     const pageHtmlWord = renderToStaticMarkup(this.myPageFunc(daysPictureDataBase64, tripAccomodationBase64, tripAccomodationOpt1Base64, tripAccomodationOpt2Base64, mapDataBase64, coverBase64, licence64));


     const htmlWord = `
 <!DOCTYPE html>
 <html
>
   <head>
   <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
   <meta  Content-Disposition: "inline"/>
   <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCielPro.css">
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>
 
   ${pageHtmlWord}

</div>
</body>
</html>
 `;

     this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}`)
   }

   render() {
     const {
       isLoading,
       errorMsg,
     } = this.state


     return (
       <>
         {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
         {isLoading ? <Loader /> : null}
         <div className="urlBox">
           <Grid container spacing={0} justifyContent="flex-start">
             <br />
             <Grid item xs={2}>

               <ActionButton
                 label="export word document"
                // target="tripDetails"
                 onClick={this.getAllPictures}
               />
             </Grid>

           </Grid>
         </div>
       </>

     );
   }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  accomodationList: state.api.accomodationList,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripRoom: state.api.tripRoom,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripDevisFinal: state.api.tripDevisFinal,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({

  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlCielProWord))
