import packageJson from '../package.json'

const dev = {
  baseUrl: `/${packageJson.baseUrl}`,
  app: {
    duyen: {
      url: 'http://localhost:3000',
    },
  },
  api: {
    duyen: {
      url: 'http://localhost:5000',
      // url: 'http://192.168.1.11:5000',
      version: 'V1',
    },
  },
  imgStorage: 'https://duyen-pictures.s3.ap-south-1.amazonaws.com',
}

const prod = {
  baseUrl: '/',
  app: {
    duyen: {
      url: 'http://app.cielchine.com',
    },
  },
  api: {
    duyen: {
      url: 'https://api-duyen-heroku-20.herokuapp.com',
      // url: 'https://salty-springs-75541.herokuapp.com',
      version: 'V1',
    },
  },
  imgStorage: 'https://duyen-pictures.s3.ap-south-1.amazonaws.com',
}

const result = window.location.href.indexOf('s3') > -1 || window.location.href.indexOf('cielchine.com') > -1 || window.location.href.indexOf('duyen.pro') > -1 || window.location.href.indexOf('d23gib511l0z2e') > -1
  ? prod
  : dev

export default result
