import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
// import axios from 'axios';
import api from '../../components/api/api'
import { Input } from '../../components'
import BootstrapButton from '../../assets/styles/bootstrapButton'
import cielChineLogo from '../../assets/images/logoCielChineHeader.png'
import cielChineLogo2x from '../../assets/images/logoCielChineHeader@2x.png'
import cielChineLogo3x from '../../assets/images/logoCielChineHeader@3x.png'

class SignIn extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userLog: PropTypes.object,
    POST_SIGNIN: PropTypes.func.isRequired,
    RESET_APP: PropTypes.func.isRequired,
  }

  static defaultProps = {
    userLog: {},
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.goTo = this.goTo.bind(this)
    this.control = this.control.bind(this)
    this.error = this.error.bind(this)
    this.saveData = this.saveData.bind(this)
    this.state = {
      email: '',
      password: '',
      ctrlError: null,
    };
  }

  componentDidMount() {
    const { RESET_APP } = this.props
    RESET_APP()
    // Load saved users in database
    api.get_list_registred_users()
    // reset token in localstorage
    localStorage.removeItem('token')
  }

  componentWillReceiveProps(nextProps) {
    const {
      userLog: userLogOld,
    } = this.props
    const {
      userLog,
    } = nextProps
    if (userLog && userLog.error) {
      this.setState({ ctrlError: userLog.error })
    } else if (!_.isEqual(userLog, userLogOld)) {
      this.saveData(userLog)
      this.goTo('trip')
    }
  }

  goTo = (dest) => {
    const { history } = this.props
    history.push(dest)
  }

  saveData = (data) => {
    localStorage.setItem('token', data.token)
    localStorage.setItem('userId', data.userId)
    localStorage.setItem('isLogged', true)
  }

  handleChange = field => (event) => {
    this.setState({ [field]: event.target.value });
  };

  control = () => {
    const {
      email,
      password,
    } = this.state
    const isEmail = /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/g
    const isPassword = /^\w{8,}$/g // [a-zA-Z0-9_]
    const ctrlEmail = isEmail.test(email)
    const ctrlPassword = isPassword.test(password)
    const isValidForm = ctrlEmail && ctrlPassword
    if (!isValidForm) {
      this.setState({ ctrlError: 'login or password does not respect expected format' })
    } else {
      this.setState({ ctrlError: null })
      this.sendForm()
    }
    return isValidForm
  }

  error = () => {
    const { ctrlError } = this.state
    return ctrlError ? <p style={{ color: 'red' }}>{ctrlError}</p> : null
  }

  sendForm = () => {
    const {
      email,
      password,
    } = this.state
    const { POST_SIGNIN } = this.props
    // post login user
    POST_SIGNIN(email, password)
  }

  render() {
    const {
      email,
      password,
    } = this.state
    return (
      <div className="signin">
        <img
          className="logo"
          src={cielChineLogo}
          srcSet={`${cielChineLogo2x} 2x, ${cielChineLogo3x} 3x`}
          alt=""
          style={{ width: '200px', marginBottom: '30px' }}
        />
        {this.error()}
        <Input
          id="email"
          label="Email"
          value={email}
          className="inputWidth"
          onChange={this.handleChange('email')}
          margin="normal"
          required
        />
        <Input
          id="password"
          label="Password"
          value={password}
          type="password"
          className="inputWidth"
          onChange={this.handleChange('password')}
          margin="normal"
          required
        />
        <BootstrapButton
          type="button"
          onClick={() => this.control()}
          variant="contained"
          color="primary"
          disableRipple
        >
          SEND
        </BootstrapButton>
        <p
          className="createAccount"
          onClick={() => this.goTo('recoverPassword')}
        >
          + I forgot my password
        </p>
        <p
          className="createAccount"
          onClick={() => this.goTo('signup')}
        >
          + Create a new account
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userLog: state.api.userLog,
})

const mapDispatchToProps = dispatch => ({
  RESET_APP: () => dispatch({
    type: 'RESET_APP',
  }),
  POST_SIGNIN: (email, password) => dispatch({
    type: 'POST_SIGNIN',
    email,
    password,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
