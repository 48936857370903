/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Interweave from 'interweave';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { CssCheckbox } from '../../configCSS'
import {
  Input,
  ModalComponent,
  ModalActivity,
  ActionButton,
} from '..'

const locationFormatRegExp = new RegExp(' ', 'g')

class DayLineBilling extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    suppliersList: PropTypes.array,
    tripActivities: PropTypes.object,
    tripDetails: PropTypes.object,
    validateError: PropTypes.bool,
    activityList: PropTypes.array,
    updateItems: PropTypes.func,
    upDateIsActivityChanged: PropTypes.func,
    locationList: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripContent: PropTypes.array,
    putTripDefaultActivities: PropTypes.object.isRequired,
    putTripActivity: PropTypes.object.isRequired,
    putActivityContent: PropTypes.object,
    deleteTripActivities: PropTypes.object.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    PUT_TRIP_DEFAULT_ACTIVITIES: PropTypes.func.isRequired,
    RESET_PUT_TRIP_DEFAULT_ACTIVITIES: PropTypes.func.isRequired,
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    GET_SUPPLIERS_LIST: PropTypes.func.isRequired,
    PUT_TRIP_ACTIVITY: PropTypes.func.isRequired,
    PUT_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    RESET_PUT_TRIP_ACTIVITY: PropTypes.func.isRequired,
    RESET_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    RESET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    RESET_DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    suppliersList: [],
    item: {},
    index: 0,
    validateError: false,
    tripAllDays: [],
    activityList: [],
    tripActivities: {},
    tripContent: [],
    putActivityContent: {},
    userAccount: {},
    locationList: [],
    tripDetails: {},
    updateItems: () => {},
    upDateIsActivityChanged: () => {},
  }

  constructor(props) {
    super(props);
    this.displayDescritption = this.displayDescritption.bind(this)
    this.displayAutocompleteActivity = this.displayAutocompleteActivity.bind(this)
    this.handleActivityDayChange = this.handleActivityDayChange.bind(this)
    this.saveActivityPersonalised = this.saveActivityPersonalised.bind(this)
    this.loadResponse = this.loadResponse.bind(this)
    this.resetStore = this.resetStore.bind(this)
    this.onChangeFilter = this.onChangeFilter.bind(this)
    this.getOptionLabelSupplier = this.getOptionLabelSupplier.bind(this)
    this.handleOpenDeleteAll = this.handleOpenDeleteAll.bind(this)
    this.checkDeleteActivities = this.checkDeleteActivities.bind(this)
    this.displayAutocompleteSuppliersFilter = this.displayAutocompleteSuppliersFilter.bind(this)
    this.handleDeleteOne = this.handleDeleteOne.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      message: '',
      messageSuccess: false,
      isModalOpen: false,
      isModalOpenDate: false,
      isModalOpenDelete: false,
      isModalOpen2: false,
      actionButtonOff: false,
      filterLoc: true,
      putActivity: '',
      idActivity: 0,
      activitySelected: null,
      activityType: '',
      tripActivitiesState: '',
      filterTheme: [
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
        { id_supplier: '', supplier: '' },
      ],
    }
  }

  componentDidMount() {
    const { userId, tripActivitiesState } = this.state
    const {
      item,
      GET_SUPPLIERS_LIST,
      suppliersList,
      index,
      tripActivities,
    } = this.props
    if (_.isEmpty(suppliersList) && index === 0) {
      GET_SUPPLIERS_LIST(userId)
    }
    if (tripActivities && _.isEmpty(tripActivitiesState)) {
      this.setState({
        tripActivitiesState: tripActivities,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      tripActivitiesState: tripActivitiesStateOld,
    } = prevState
    const {
      tripActivities,
      RESET_PUT_TRIP_DEFAULT_ACTIVITIES,
      RESET_TRIP_ACTIVITIES,
      putTripDefaultActivities,
    } = nextProps
    if (!_.isEmpty(putTripDefaultActivities) && 'success' in putTripDefaultActivities && !_.isEmpty(tripActivities)) {
      RESET_PUT_TRIP_DEFAULT_ACTIVITIES()
      setTimeout(() => {
        RESET_TRIP_ACTIVITIES()
      }, 500);
      return {
        tripActivitiesState: tripActivities,
      }
    }

    return null;
  }


  componentDidUpdate() {
    const { token, userId } = this.state
    const {
      item,
      putTripDefaultActivities,
      putTripActivity,
      putActivityContent,
      deleteTripActivities,
      GET_ACTIVITY_LIST,
      GET_TRIP_ACTIVITIES,
      RESET_TRIP_ACTIVITIES,
      upDateIsActivityChanged,
      tripContent,
    } = this.props
    if (!_.isEmpty(putTripDefaultActivities) && 'success' in putTripDefaultActivities) {
      if (putTripDefaultActivities.data && putTripDefaultActivities.data.dayIndex === item.dayIndex) {
        GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
        this.resetStore()
      }
    }

    if (putTripActivity && putTripActivity.success) {
      if (putTripActivity.data.dayIndex === item.dayIndex) {
        RESET_TRIP_ACTIVITIES()
        upDateIsActivityChanged(true)
        this.loadResponse(putTripActivity)
        this.resetStore()
      }
    }
    if (putActivityContent && putActivityContent.success) {
      GET_ACTIVITY_LIST(userId)
      this.loadResponse(putActivityContent)
      this.resetStore()
    }

    if (!_.isEmpty(deleteTripActivities) && 'success' in deleteTripActivities) {
      if (deleteTripActivities.data.dayIndex === item.dayIndex) {
        RESET_TRIP_ACTIVITIES()
        upDateIsActivityChanged(true)
        this.loadResponse(deleteTripActivities)
        this.resetStore()
      }
    }
  }

  componentWillUnmount() {
    const { token } = this.state
    const {
      tripContent,
      GET_TRIP_ACTIVITIES,
    } = this.props
    GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
  }


  loadResponse = (response) => {
    const {
      RESET_DELETE_TRIP_ACTIVITIES,
      RESET_ACTIVITY_CONTENT,
      RESET_PUT_TRIP_ACTIVITY,
    } = this.props
    RESET_PUT_TRIP_ACTIVITY()
    RESET_DELETE_TRIP_ACTIVITIES()
    RESET_ACTIVITY_CONTENT()
    this.setState({
      message: response.message,
      messageSuccess: response.success,
    })
  }

  resetStore = () => {
    setTimeout(() => {
      this.setState({
        message: '',
        messageSuccess: false,
        actionButtonOff: false,
      })
    }, 2000);
  }

  handleOpenDeleteAll = () => {
    const {
      token,
      idActivity,
      tripActivitiesState,
    } = this.state
    const {
      item,
      tripDetails,
      DELETE_TRIP_ACTIVITIES,
      tripContent,
    } = this.props

    DELETE_TRIP_ACTIVITIES(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, idActivity, 'durationUp')
    const longueur = length.tripActivitiesState
    const filteredTripActivitiesState = Object.keys(tripActivitiesState).reduce((acc, key) => {
      acc[key] = tripActivitiesState[key].filter(activity => activity.id_activity !== idActivity);
      return acc;
    }, {});
    this.setState({
      isModalOpenDelete: false,
      tripActivitiesState: filteredTripActivitiesState,
    })
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }

  handleDeleteOne = () => {
    const {
      token,
      idActivity,
      tripActivitiesState,
    } = this.state
    const {
      item,
      tripDetails,
      DELETE_TRIP_ACTIVITIES,
      tripContent,
    } = this.props

    DELETE_TRIP_ACTIVITIES(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, idActivity, 1)
    tripActivitiesState[item.dayIndex] = tripActivitiesState[item.dayIndex].filter(tAS => tAS.id_activity !== idActivity);

    this.setState({
      isModalOpenDelete: false,
    })
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }

  checkDeleteActivities = (token, id_circuit, id_journee, dayIndex, id_activity, nbActivities) => {
    const {
      tripActivitiesState,
    } = this.state
    const {
      DELETE_TRIP_ACTIVITIES,
    } = this.props
    const tripActivitiesFlat = [...new Set(Object.values(tripActivitiesState)
      .flat())];
    const nbActivitesSaved = tripActivitiesFlat.filter(activite => activite.id_activity === id_activity).length;

    if (nbActivitesSaved > 1) {
      this.setState({
        isModalOpenDelete: true,
        idActivity: id_activity,
      })
    } else {
      DELETE_TRIP_ACTIVITIES(token, id_circuit, id_journee, dayIndex, id_activity, nbActivities)
      tripActivitiesState[dayIndex] = tripActivitiesState[dayIndex].filter(tAS => tAS.id_activity !== id_activity);
    }
  }

  handleActivityDayChange = (value, reason, detail, nb) => {
    const {
      token,
      tripActivitiesState,
    } = this.state
    const {
      item,
      tripDetails,
      PUT_TRIP_ACTIVITY,
      DELETE_TRIP_ACTIVITIES,
      tripContent,
    } = this.props

    // const tabArrayId = item.day_activity ? item.day_activity.split(',') : []
    const index = item.dayIndex
    let tabArrayIdAct = []
    if (!_.isEmpty(tripActivitiesState.index)) {
      tabArrayIdAct = tripActivitiesState.index.map(act => act.id_activity)
    }

    // Verifier si l'activité n'est pas deja enregistre dans la liste
    const isAlreadyActivity = detail && detail.option && !_.isEmpty(tabArrayIdAct) ? tabArrayIdAct.includes(detail.option.id_activity) : false;
    if (isAlreadyActivity && reason === 'select-option') reason = 'no-option'
    let nbActivities = 1

    switch (reason) {
      case 'clear':
        nbActivities = tripActivitiesState[item.dayIndex] ? tripActivitiesState[item.dayIndex].length : 1
        DELETE_TRIP_ACTIVITIES(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, null, nbActivities)
        tripActivitiesState[item.dayIndex] = []
        this.setState({
          actionButtonOff: false,
        })
        break;
      case 'remove-option':
        this.checkDeleteActivities(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, detail.option.id_activity, nbActivities)
        break;
      case 'select-option':
        if (nb === 0 || nb === 1 || nb === 3) {
          this.setState({
            isModalOpenDate: true,
          })
        }
        if (nb !== 0 && nb !== 1 && nb !== 3) {
          this.setState({
            isModalOpen: true,
          })
        }
        this.setState({
          putActivity: 'add-activity',
          activitySelected: detail.option,
          activityType: detail.option.activity_type,
        })


        break;
      default:
        break;
    }
    return true
  }

  // ***************************
  // update items (parent)
  callback = () => {
    // TODO UPDATE Journee
    // item.id_jounee -> item.day_activity
    const { updateItems, item } = this.props

    updateItems(item)
  }


  onChangeFilter = nb => (event, value) => {
    const { filterTheme } = this.state;

    // Copiez l'objet filterTheme
    const updatedFilterTheme = [...filterTheme];

    // Mettez à jour les valeurs id_supplier et supplier à l'index nb
    updatedFilterTheme[nb] = {
      ...updatedFilterTheme[nb], // Assurez-vous de copier les valeurs existantes
      id_supplier: value ? value.id_supplier : '',
      supplier: value ? value.supplier : '',
    };

    // Mettez à jour le state avec le nouvel objet filterTheme
    this.setState({
      filterTheme: updatedFilterTheme,
    });
  };

  getOptionLabelSupplier = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.supplier) {
      return ''
    }
    // Regular option
    return option.supplier;
  }

  displayAutocompleteSuppliersFilter = (nb) => {
    const {
      suppliersList,
      locationList,
      item,
    } = this.props
    const {
      filterTheme,
    } = this.state

    const filteredLieuGrand = locationList.filter(ll => ll.id_lieu === item.lieu_debut_journee);
    const filteredLieuGrandFin = locationList.filter(ll => ll.id_lieu === item.lieu_fin_journee);
    const listFilteredByLocation = !_.isEmpty(filteredLieuGrand) && suppliersList.filter(sL => ((
      sL.id_lieu === 1 && sL.id_lieu_grand === 1)
       || (sL.id_lieu === item.lieu_fin_journee)
       || (sL.id_lieu_grand === filteredLieuGrand[0].id_lieu_grand)
       || (sL.id_lieu_grand === filteredLieuGrandFin[0].id_lieu_grand)
       || (sL.id_lieu === item.lieu_debut_journee))).filter(sl => ((sl.id_activity_theme === nb)
       || (sl.activity_theme === 'Multi')));
    return (
      <Autocomplete
        id="supplier"
        value={filterTheme[nb]}
        options={listFilteredByLocation}
        getOptionLabel={this.getOptionLabelSupplier}
        onChange={this.onChangeFilter(nb)}
        renderInput={params => (
          <Input
            {...params}
            label="Filter by supplier"
            margin="normal"
            variant="outlined"
          />
        )}
      />
    )
  }

  displayAutocompleteActivity = (field, list, theme, nb) => {
    const {
      validateError,
      filterTheme,
      filterLoc,
      tripActivitiesState,
    } = this.state
    const {
      item,
      tripContent,
      locationList,
      userAccount,
    } = this.props
    const activityTitle = `${userAccount.language}_activity`
    const filteredLieuGrand = locationList.filter(ll => ll.id_lieu === item.lieu_debut_journee);
    const filteredLieuGrandFin = locationList.filter(ll => ll.id_lieu === item.lieu_fin_journee);
    let listFilteredByLocation = ''
    if (filterTheme[nb].id_supplier && filterTheme[nb].id_supplier !== '') {
      listFilteredByLocation = list.filter(al => (al.id_supplier === filterTheme[nb].id_supplier)).filter(al => al.id_activity_theme === nb)
    } else if (filterLoc) {
      listFilteredByLocation = list.filter(aL => ((aL.active !== '2' && aL.id_location === item.lieu_fin_journee)
      || (aL.active !== '2' && aL.id_location_grand === filteredLieuGrand[0].id_lieu_grand)
      || (aL.active !== '2' && aL.id_location_grand === filteredLieuGrandFin[0].id_lieu_grand)
      || (aL.active !== '2' && aL.id_location === item.lieu_debut_journee)
      || (aL.universel === 1)
      || (aL.id_location === item.lieu_debut_journee && aL.id_circuit === tripContent[0].id_circuit))).filter(al => al.id_activity_theme === nb)
    } else {
      listFilteredByLocation = list.filter(al => al.id_activity_theme === nb)
    }
    const activities = tripActivitiesState[item.dayIndex] || [];
    const dayActivities = list.filter(al => activities.map(ac => ac.id_activity).includes(al.id_activity)).filter(al => al.id_activity_theme === nb)
    const participant = dayActivities.map(da => tripActivitiesState[item.dayIndex].filter(ta => ta.id_activity === da.id_activity)[0])
    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    const StyledChip = withStyles({
      label: {
        fontSize: '20px', // Définir la taille de la police en pixels
      },
    })(Chip);
    return (
      <Autocomplete
        id={`${field}-field`}
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={dayActivities}
        onChange={(event, newValue, reason, detail) => {
          this.handleActivityDayChange(newValue, reason, detail, nb);
        }}
        options={listFilteredByLocation}
        getOptionLabel={option => `${option[activityTitle]} --- Location: ${option.location} --- Province: ${option.locationGrand} --- Supplier: ${option.supplier}`}
        renderTags={(value, getTagProps) => value.map((option, index) => (option.activity_type === 'individual'
          ? (
            <StyledChip
              title={` 
                       Activity Name: ${option.activity}\n
                       Nb Senior: ${participant[index].nb_senior}\n
                       Nb Adult: ${participant[index].nb_adult}\n
                       Nb Children: ${participant[index].nb_child}\n
                       Activity type: ${option.activity_type}\n                        
                       Currency: ${option.currency}
                       `}
              style={FondDefautIndividual}
              variant="outlined"
              label={`${option[activityTitle].length > 30 ? `${option[activityTitle].substring(0, 30)}...` : option[activityTitle]}` || ''}
              {...getTagProps({ index })}
            />
          )
          : (
            <StyledChip
              title={` 
                Activity Name: ${option.activity}\n
                Nb: ${participant[index].nb_adult}\n
                Activity type: ${option.activity_type}\n
                Currency: ${option.currency}
                `}
              style={FondDefautGroup}
              variant="outlined"
              label={`${option.activity.length > 30 ? `${option.activity.substring(0, 30)}...` : option.activity}` || ''}
              {...getTagProps({ index })}
            />
          )))
        }
        renderInput={params => (
          <Input
            {...params}
            margin="normal"
            placeholder={theme}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }


  // Display description wwhen click on Number line
  displayDescritption = (stripe) => {
    const {
      message,
      messageSuccess,
      isModalOpen,
      isModalOpen2,
      isModalOpenDate,
      isModalOpenDelete,
      activityType,
      filterLoc,
      tripActivitiesState,
      actionButtonOff,
    } = this.state
    const {
      item,
      activityList,
      tripDetails,
      tripContent,
      tripAllDays,
      index,
    } = this.props
    const activities = tripActivitiesState[item.dayIndex] ? tripActivitiesState[item.dayIndex].filter(TAS => TAS.id_activity !== 75) : [];
    let backgroundColor = ''
    if (!_.isEmpty(message)) {
      backgroundColor = messageSuccess ? 'valid' : 'error';
    }
    const defaultSenior = tripDetails.nb_senior && !isNaN(parseInt(tripDetails.nb_senior, 10)) ? parseInt(tripDetails.nb_senior, 10) : 0
    const defaultAdult = tripDetails.nb_adulte && !isNaN(parseInt(tripDetails.nb_adulte, 10)) ? parseInt(tripDetails.nb_adulte, 10) : 1
    const defaultChildren = tripDetails.nb_enfant && !isNaN(parseInt(tripDetails.nb_enfant, 10)) ? parseInt(tripDetails.nb_enfant, 10) : 0
    const longueurVoyage = tripAllDays.length
    const isActionButtonDisabled = ((!_.isEmpty(activities) && (activities.length !== 1 || activities[0].id_activity !== 75)) || actionButtonOff || _.isEmpty(item.day_activity))

    const inputValue = activityType === 'individual' ? (
      [
        'senior',
        'adult',
        'children',
      ]
    ) : (
      [
        'nb',
      ]
    )

    const inputValueDate = activityType === 'individual' ? (
      [
        'senior',
        'adult',
        'children',
        'date',
      ]
    ) : (
      [
        'nb',
        'date',
      ]
    )
    const desc = `desc_jour_${index + 1}`;

    return (
      <div className="description">
        <Grid
          container
          spacing={1}
          className="dayLineBilling"
        >
          {!_.isEmpty(item) && (
            <Grid item xs={9}>
              <Grid
                container
                spacing={1}
              >
                <Grid item xs={12}>
                  -
                  <FormControlLabel
                    control={(
                      <CssCheckbox
                        checked={filterLoc ? !!filterLoc : false}
                        onChange={() => this.setState({ filterLoc: !filterLoc })}
                        name="filter by location or province"
                      />
              )}
                    label="filter by location or province"
                  />
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Food</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(2, 'Food')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Food', 2)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Transportation</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(3, 'Transportation')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Transportation', 3)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Activity</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(4, 'Activity')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Activity', 4)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Service guide</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(5, 'Service guide')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Service guide', 5)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Flight</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(6, 'Flight')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Flight', 6)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Extra</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(7, 'Extra')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Extra', 7)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Other</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(8, 'Other')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Other', 8)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <div className="TitreDashBoard">Distance/Route in km</div>
                  </Grid>
                  <Grid item xs={3}>
                    {this.displayAutocompleteSuppliersFilter(9, 'Distance')}
                  </Grid>
                  <Grid item xs={9}>
                    {this.displayAutocompleteActivity('day_activity', activityList, 'Distance', 9)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={3}>
            <ActionButton
              label={_.isEmpty(item.day_activity) ? 'No activities to Load' : 'Load'}
              onClick={e => this.getDefaultActivities(e)}
              title="Load default activities for this day"
              disabled={isActionButtonDisabled}
            />
            <ActionButton
              label="Create"
              title="Create a unique activity for this day"
              onClick={this.handleOpenModal2}
            />
            <Interweave content={tripContent[0][desc]} />

          </Grid>
          <Grid item xs={1} />
          <Grid item className={`msgRequestSmall ${backgroundColor}`}>
            {message}
          </Grid>
        </Grid>
        <ModalComponent
          isModalOpen={isModalOpen}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={participant => this.updateTripDayActivity(participant)}
          title="Enter number."
          disabled={false}
          modalContent="trip_activity"
          type="number"
          inputValue={inputValue}
          defaultAdult={defaultAdult}
          defaultChildren={defaultChildren}
          defaultSenior={defaultSenior}
        />
        <ModalComponent
          isModalOpen={isModalOpenDate}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={participant => this.updateTripDayActivity(participant)}
          title="Enter number."
          disabled={false}
          modalContent="dateToUpdate"
          dateDepart={tripDetails && !_.isEmpty(tripDetails.date_depart) && moment(tripDetails.date_depart, 'YYYY-MM-DD').add(index, 'd').format('YYYY-MM-DD')}
          longueurVoyage={longueurVoyage - index}
          type="text"
          inputValue={inputValueDate}
          defaultAdult={defaultAdult}
          defaultChildren={defaultChildren}
          defaultSenior={defaultSenior}
        />
        <ModalComponent
          isModalOpen={isModalOpenDelete}
          onClose={this.handleClose}
          onResponseNoAll={this.handleClose}
          onResponseNo={this.handleDeleteOne}
          onResponseYes={this.handleOpenDeleteAll}
          modalContent="deleteActivity"
          type="deleteActivity"
          tripleAction
          title="Other days have the same activity, do you want to delete all(ALL) or only the one of this day(one)?"
        />
        <ModalActivity
          isModalOpen={isModalOpen2}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={activityContent => this.saveActivityPersonalised(activityContent)}
          title="Create the activity"
          modalContent="activity_personalised"
        />
      </div>
    )
  }

  getDefaultActivities = (e) => {
    e.preventDefault()
    const {
      token,
    } = this.state
    const {
      item,
      tripDetails,
      PUT_TRIP_DEFAULT_ACTIVITIES,
      RESET_TRIP_ACTIVITIES,
      upDateIsActivityChanged,
      tripContent,
    } = this.props
    const nbSenior = tripDetails.nb_senior && !isNaN(parseInt(tripDetails.nb_senior, 10)) && parseInt(tripDetails.nb_senior, 10)
    const nbAdult = tripDetails.nb_adulte && !isNaN(parseInt(tripDetails.nb_adulte, 10)) && parseInt(tripDetails.nb_adulte, 10)
    const nbChild = tripDetails.nb_enfant && !isNaN(parseInt(tripDetails.nb_enfant, 10)) && parseInt(tripDetails.nb_enfant, 10)

    // check nombre de personne adult et enfant
    if (!tripDetails.nb_senior && !tripDetails.nb_adulte && !tripDetails.nb_enfant) {
      this.setState({
        isModalOpen: true,
        putActivity: 'default-activities',
      })
    } else {
      RESET_TRIP_ACTIVITIES()
      upDateIsActivityChanged(true)
      PUT_TRIP_DEFAULT_ACTIVITIES(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, nbSenior, nbAdult, nbChild)
      this.setState({
        tripActivitiesState: '',
        actionButtonOff: true,
      })
    }
  }

  handleClose = () => {
    const { scrollY } = window;
    this.setState({
      isModalOpen: false,
      isModalOpen2: false,
      isModalOpenDate: false,
      isModalOpenDelete: false,
    })
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }

  handleOpenModal2 = () => {
    this.setState({
      isModalOpen2: true,
    })
  }

  updateTripDayActivity = (participant) => {
    const {
      token,
      putActivity,
      activitySelected,
      activityType,
      tripActivitiesState,
    } = this.state
    const {
      tripContent,
      PUT_TRIP_DEFAULT_ACTIVITIES,
      PUT_TRIP_ACTIVITY,
      RESET_TRIP_ACTIVITIES,
      item,
      upDateIsActivityChanged,
      tripActivities,
    } = this.props
    let nbSenior = null
    let nbAdult = null
    let nbChild = null
    if (activityType === 'individual') {
      nbSenior = participant && participant.senior && participant.senior
      nbAdult = participant && participant.adult && participant.adult
      nbChild = participant && participant.children && participant.children
    } else {
      nbAdult = participant && participant.nb && participant.nb
    }

    if (putActivity === 'default-activities') {
      RESET_TRIP_ACTIVITIES()
      upDateIsActivityChanged(true)
      PUT_TRIP_DEFAULT_ACTIVITIES(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, nbSenior, nbAdult, nbChild)
      this.setState({
        tripActivitiesState: '',
      })
    } else if (putActivity === 'add-activity') {
      const oneDayMore = _.cloneDeep(tripActivitiesState)
      if (participant.date && participant.date !== null) {
        _.times(participant.date, (index) => {
          const updatedDayIndex = item.dayIndex + index;
          // Exécutez votre requête PUT_TRIP_ACTIVITY ici
          PUT_TRIP_ACTIVITY(token, tripContent[0].id_circuit, item.id_journee, updatedDayIndex, activitySelected.id_activity, nbSenior, nbAdult, nbChild)
          if (!oneDayMore[updatedDayIndex]) {
            // Si oneDayMore[item.dayIndex] n'existe pas, initialisez-le comme un tableau vide
            oneDayMore[updatedDayIndex] = [];
          }
          oneDayMore[updatedDayIndex].push({
            id_activity: activitySelected.id_activity,
            activity: activitySelected.activity,
            nb_adult: nbAdult,
            nb_senior: nbSenior,
            nb_child: nbChild,
            price_adulthighseason: activitySelected.price_adulthighseason,
            price_adultlowseason: activitySelected.price_adultlowseason,
            price_adultpeakseason: activitySelected.price_adultpeakseason,
            price_childhighseason: activitySelected.price_childhighseason,
            price_childlowseason: activitySelected.price_childlowseason,
            price_childpeakseason: activitySelected.price_childpeakseason,
            price_seniorhighseason: activitySelected.price_seniorhighseason,
            price_seniorlowseason: activitySelected.price_seniorlowseason,
            price_seniorpeakseason: activitySelected.price_seniorpeakseason,
            activity_type: activitySelected.activity_type,
            activity_theme: activitySelected.activity_theme,
            currency: activitySelected.currency,
          })
          this.setState({
            tripActivitiesState: oneDayMore,
          })
        });
      } else {
        PUT_TRIP_ACTIVITY(token, tripContent[0].id_circuit, item.id_journee, item.dayIndex, activitySelected.id_activity, nbSenior, nbAdult, nbChild)
        if (!oneDayMore[item.dayIndex]) {
          // Si oneDayMore[item.dayIndex] n'existe pas, initialisez-le comme un tableau vide
          oneDayMore[item.dayIndex] = [];
        }
        oneDayMore[item.dayIndex].push({
          id_activity: activitySelected.id_activity,
          activity: activitySelected.activity,
          nb_adult: nbAdult,
          nb_senior: nbSenior,
          nb_child: nbChild,
          price_adulthighseason: activitySelected.price_adulthighseason,
          price_adultlowseason: activitySelected.price_adultlowseason,
          price_adultpeakseason: activitySelected.price_adultpeakseason,
          price_childhighseason: activitySelected.price_childhighseason,
          price_childlowseason: activitySelected.price_childlowseason,
          price_childpeakseason: activitySelected.price_childpeakseason,
          price_seniorhighseason: activitySelected.price_seniorhighseason,
          price_seniorlowseason: activitySelected.price_seniorlowseason,
          price_seniorpeakseason: activitySelected.price_seniorpeakseason,
          activity_type: activitySelected.activity_type,
          activity_theme: activitySelected.activity_theme,
          currency: activitySelected.currency,
        })
      }
      this.setState({
        tripActivitiesState: oneDayMore,
      })
    }
    this.setState({
      isModalOpen: false,
      isModalOpenDate: false,
      isModalOpenDelete: false,
      putActivity: '',
      activitySelected: null,
    })
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }


  saveActivityPersonalised = (activityContent) => {
    const {
      token,
    } = this.state
    const {
      PUT_ACTIVITY_CONTENT,
      tripContent,
      item,
    } = this.props
    console.log(activityContent)
    activityContent.id_circuit = tripContent[0].id_circuit
    activityContent.active = 2
    activityContent.id_lieu = item.lieu_debut_journee
    activityContent.id_activity_theme = activityContent.activity_theme
    console.log(activityContent)
    PUT_ACTIVITY_CONTENT(token, activityContent, 'create')
    this.setState({
      isModalOpen2: false,
    })
  }

  // ***************************
  render() {
    const {
      index,
      suppliersList,
    } = this.props

    // background striped line by line
    return (
      <div>
        {/* </div> */}
        {this.displayDescritption()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activityList: state.activityReducer.activityList,
  putTripDefaultActivities: state.billingReducer.putTripDefaultActivities,
  putActivityContent: state.activityReducer.putActivityContent,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  putTripActivity: state.billingReducer.putTripActivity,
  tripContent: state.api.tripContent,
  tripAllDays: state.api.tripAllDays,
  tripActivities: state.billingReducer.tripActivities,
  deleteTripActivities: state.billingReducer.deleteTripActivities,
  suppliersList: _.filter(state.suppliersReducer.suppliersList, l => l.id_supplier !== 1),
  userAccount: state.userAccountReducer.userAccount,
})
const mapDispatchToProps = dispatch => ({
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),
  PUT_TRIP_DEFAULT_ACTIVITIES: (token, idCircuit, idJournee, dayIndex, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_DEFAULT_ACTIVITIES',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    nbSenior,
    nbAdult,
    nbChild,
  }),
  RESET_PUT_TRIP_DEFAULT_ACTIVITIES: () => dispatch({
    type: 'RESET_PUT_TRIP_DEFAULT_ACTIVITIES',
  }),
  RESET_TRIP_ACTIVITIES: () => dispatch({
    type: 'RESET_TRIP_ACTIVITIES',
  }),
  PUT_TRIP_ACTIVITY: (token, idCircuit, idJournee, dayIndex, idActivity, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_ACTIVITY',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbSenior,
    nbAdult,
    nbChild,
  }),
  RESET_PUT_TRIP_ACTIVITY: () => dispatch({
    type: 'RESET_PUT_TRIP_ACTIVITY',
  }),
  DELETE_TRIP_ACTIVITIES: (token, idCircuit, idJournee, dayIndex, idActivity, nbActivities) => dispatch({
    type: 'DELETE_TRIP_ACTIVITIES',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbActivities,
  }),
  PUT_ACTIVITY_CONTENT: (token, activityContent, action) => dispatch({
    type: 'PUT_ACTIVITY_CONTENT',
    token,
    activityContent,
    action,
  }),
  RESET_ACTIVITY_CONTENT: () => dispatch({
    type: 'RESET_ACTIVITY_CONTENT',
  }),
  RESET_DELETE_TRIP_ACTIVITIES: () => dispatch({
    type: 'RESET_DELETE_TRIP_ACTIVITIES',
  }),
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
  GET_SUPPLIERS_LIST: userId => dispatch({
    type: 'GET_SUPPLIERS_LIST',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DayLineBilling))
