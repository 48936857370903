/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/accessible-emoji */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'moment/locale/it';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import stage from '../../../config'
import {
  ActionButton,
  TextEditor,
  Loader,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlCielPro extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripRoom: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    tripBilling: PropTypes.object,
    tripDevisFinal: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    userAccount: PropTypes.object,
    accomodationList: PropTypes.array,
  }

  static defaultProps = {
    tripDetails: [],
    userAccount: {},
    tripAllDays: [],
    tripRoom: [],
    tripDevisFinal: {},
    tripContent: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    accomodationList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.createCustomMarkerIcon = this.createCustomMarkerIcon.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.handleOSizeZoomChange = this.handleOSizeZoomChange.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      isLoading: false,
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',
      zoomCarte: 100,
      typeCarte: 0,
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteSense-size', `${event.currentTarget.value}px`)
  }

  handleOSizeZoomChange = (event) => {
    this.setState({
      zoomCarte: event.currentTarget.value,
    })
  }

handlePrint = (html) => {
  const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

  const newWindow = window.open('', '_blank');
  newWindow.document.write(content);
  newWindow.document.close();
}

handleSave = (devisContent) => {
  const {
    PUT_TRIP_DEVIS_FINAL,
    tripDetails,
    tripDevisFinal,
  } = this.props
  const {
    token,
  } = this.state
  const tripDevisFinalServeur = {}
  tripDevisFinalServeur.textHtml = devisContent
  tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
  if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
  } else {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
  }
}

handleEditorChange = (content) => {
  this.setState({
    devisContent: content,
  });
}


  dropFile = (html) => {
    const {
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', 'devis')
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }
    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis,
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }


  // Get array of images


  createCustomMarkerIcon = (index) => {
    const canvas = document.createElement('canvas');
    canvas.width = 40; // Largeur de l'icône
    canvas.height = 40; // Hauteur de l'icône
    const context = canvas.getContext('2d');

    // Dessin du cercle de fond
    context.beginPath();
    context.arc(20, 20, 18, 0, 2 * Math.PI); // Cercle centré
    context.fillStyle = 'red'; // Couleur de fond
    context.fill();
    context.closePath();

    // Ajout du texte (l'index)
    context.fillStyle = 'white'; // Couleur du texte
    context.font = 'bold 14px Arial'; // Police du texte
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(index, 20, 20); // Position du texte

    // Retourne une image comme icône
    return canvas.toDataURL();
  }


  render() {
    const tableauHebergement = (roomAccoArray, roomAcco1Array, roomAcco2Array) => {
      const {
        accomodationList,
        tripAllDays,
      } = this.props
      // Initialiser les compteurs


      return (roomAccoArray.map((item, i) => {
        const hebTrouve = parseInt(roomAccoArray[i], 10) === 1 ? 'none' : (

          accomodationList.find(objet => objet.id_hebergement === parseInt(roomAccoArray[i], 10))
        )
        const hebTrouve1 = parseInt(roomAcco1Array[i], 10) === 1 ? 'none' : (

          accomodationList.find(objet => objet.id_hebergement === parseInt(roomAcco1Array[i], 10))
        )
        const hebTrouve2 = parseInt(roomAcco2Array[i], 10) === 1 ? 'none' : (

          accomodationList.find(objet => objet.id_hebergement === parseInt(roomAcco2Array[i], 10))
        )
        return (
          {
            lieu_fin_journee: tripAllDays[i].lieu_fin_journee,
            nomHeb: hebTrouve === 'none' ? 'none' : hebTrouve.nom_hebergement,
            nomHeb1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.nom_hebergement,
            nomHeb2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.nom_hebergement,
            adresse: hebTrouve === 'none' ? 'none' : hebTrouve.adresse,
            adresse1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.adresse,
            adresse2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.adresse,
            image_hebergement: hebTrouve === 'none' ? 'none' : hebTrouve.image,
            image_hebergement1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.image,
            image_hebergement2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.image,
            desc_hebergement: hebTrouve === 'none' ? 'none' : hebTrouve.fr_desc_hebergement,
            desc_hebergement1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.fr_desc_hebergement,
            desc_hebergement2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.fr_desc_hebergement,
            heb: roomAccoArray[i],
            heb1: roomAcco1Array[i],
            heb2: roomAcco2Array[i],
            day_duration: tripAllDays[i].day_duration,
            cle: `${roomAccoArray[i]}${roomAcco1Array[i]}${roomAcco2Array[i]}${tripAllDays[i].lieu_fin_journee}`,
          }

        )
      })
      )
    }


    const findOcc = (arr, key) => {
      const arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }

    const {
      tripDetails,
      userAccount,
      tripContent,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripPension,
      tripBilling,
      companyContent,
      informationsContent,
      locationList,
      tripRoom,
    } = this.props

    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
      hebergement,
      hebergement_opt1,
      hebergement_opt2,
    } = tripContent[0]

    const {
      langage,
      code_devis,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      map_localisation,
      centre_interet,
      desc_voyage,
      cover_image,
    } = tripDetails[0]
    const {
      urlDevis,
      dateUpdateUrl,
      zoomCarte,
      devisContent,
      isLoading,
      typeCarte,
    } = this.state

    // get langage
    // *****************************************************************
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    const guideArray = _.split(guide, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomAccoArray = _.split(hebergement, ',')
    const roomAcco1Array = _.split(hebergement_opt1, ',')
    const roomAcco2Array = _.split(hebergement_opt2, ',')
    const hasDifferentValueAcco1 = roomAcco1Array.some(value => value !== '1');
    const hasDifferentValueAcco2 = roomAcco2Array.some(value => value !== '1');
    const tableauUnique = tableauHebergement(roomAccoArray, roomAcco1Array, roomAcco2Array)
    const uniqueHotels = {};
    const originalOrder = [];
    tableauUnique.forEach((hotel, index) => {
      // Vérifier si l'email de l'hôtel existe dans les hôtels uniques
      if (hotel.cle && !uniqueHotels[hotel.cle]) {
        // Ajouter l'hôtel unique à l'objet uniqueHotels avec le jour de la réservation
        uniqueHotels[hotel.cle] = {
          ...hotel,
          reservedDays: [index + 1], // Ajoutez le jour de la réservation
        };
        originalOrder.push(index);
      } else if (hotel.cle && uniqueHotels[hotel.cle]) {
        // Si l'email existe déjà, ajoutez le jour de la réservation à l'array reservedDays
        uniqueHotels[hotel.cle].reservedDays.push(index + 1);
      }
    });
    const Hotel_Unique = originalOrder.map(index => uniqueHotels[tableauUnique[index].cle]);


    const Nombre_Occurence_Hotel = findOcc(tableauUnique, 'cle')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = zoomCarte === 100 ? (`https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyCUHs6GMO6QKYfSHFsslBpEHJH7Ue-xfdY`
    ) : (
      `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&zoom=${zoomCarte}&key=AIzaSyCUHs6GMO6QKYfSHFsslBpEHJH7Ue-xfdY`
    )
    const TabIncJour = tripAllDays.map(item => (item.day_duration))
    const remarque = `${langage}_remarque`
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
    const longueurVoyage = tripAllDays.length
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDayTot - 1, 'd').locale(langage).format('DD MMMM YYYY')
    const chauffeurArray = _.split(chauffeur, ',')
    const texteEntreeSite = langage && `${langage}_information_5_description`;
    const texteHotel = langage && `${langage}_invoice`;
    const imageArray = cover_image.split(',')

    const borderRadius = {
      borderRadius: 15,
    };
    const Fonddefaut = {
      backgroundColor: informationsContent.main_color,
    };

    const Colordefaut = {
      color: informationsContent.main_color,
    };

    const check = {
      fontSize: '12.0pt; mso-bidi-font-size: 10.5pt',
      fontFamily: 'MS Mincho',
      msoFareastFontFamily: 'DengXian',
      msoFareastThemeFont: 'minor-fareast',
      msoBidiFontFamily: 'MS Mincho',
      color: '#00b050',
      background: 'white',
      msoAnsiLanguage: 'FR',
      msoFaBidiLanguage: 'AR-SA',
    }

    const styleTabItiWeb = {
      color: 'black',
      textAlign: 'left',
      paddingTop: '4px',
      paddingBottom: '4px',
      textDecoration: 'none !important',
    }

    const styleTabItiWord = {
      color: 'black',
      textAlign: 'left',
      paddingTop: '2px',
      paddingBottom: '2px',
      textDecoration: 'none !important',
    }


    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => (tripRoom[index] && !_.isEmpty(tripRoom[index]) ? tripRoom[index].find(r => r.id_heb_ch === parseInt(rT, 10)).type_chambre : 'none'))
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }

    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div>

          <div className="espaceCarroussel">
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {imageArray.map((item, indexImage) => (
                  <div className={indexImage === 0 ? 'carousel-item active' : 'carousel-item'}>
                    <div className="card">
                      <img className="imajeCarou" src={`${savedImgUrl}/${companyContent.company}/${imageArray[indexImage]}`} alt="..." />
                    </div>
                  </div>
                ))}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <button id="toggleCarrousel" className="close-galerie" type="button">Fermer la galerie</button>
          </div>
          <div className="GlobalFileSense">
            <div>

              <div id="home">
                <Grid container spacing={0} align="row" justifyContent="center">
                  <Grid item xs={12}>
                    <div className="tabContainerCoverSense">
                      <table
                        className="tableauCover"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td className="container-cover" colSpan="2" width="100%">
                              <div className="cover-base">
                                <table
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td colSpan="3">
                                        <div
                                          width="800"
                                          height="750"
                                          className="container-cover1"
                                        >
                                          <img
                                            className="ImageCoverSense"
                                            src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`}
                                          />

                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td width="33%">
                                        <div
                                          width="800"
                                          height="750"
                                          className="container-cover2"
                                        >
                                          <img
                                            className="ImageCoverSense"
                                            src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`}
                                          />
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div
                                          width="800"
                                          height="750"
                                          className="container-cover2"
                                        >
                                          <img
                                            className="ImageCoverSense"
                                            src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`}
                                          />
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div
                                          width="800"
                                          height="750"
                                          className="container-cover2"
                                        >
                                          <img
                                            className="ImageCoverSense"
                                            src={`${savedImgUrl}/${companyContent.company}/${imageArray[3]}`}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <br />
                                  </tbody>
                                </table>
                                <button id="toggleCarrousel" onClick="toggleCarroussel()" className="btn-position" type="button">Afficher la galerie</button>

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td width="100%">
                              <div className="titreCover1" style={Colordefaut}>{tripDetails[0].nom_programme}</div>
                              <div className="ligneTitreCielUniq" style={Fonddefaut} />
                              <div className="fontPresentation">
                                <div> PARTICIPANTS: {travelerName} </div>
                                <br />
                                <div>Date: Du {dateDepart} au {dateFin} </div>
                                <br />
                                <div>Durée :{IncDayTot} jours {tripDetails[0].destination}</div>
                                <br />
                                <div> Programme créé par  {advisorName} de CielChine.com </div>
                                <br />
                              </div>
                            </td>
                          </tr>
                          <br />
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="sautePage" />

              <div className="CoupePasSense">

                <div id="brief" className="titreSense2" style={Colordefaut}>Itinéraire en bref</div>
                <div className="mettreRouge">
                  <table
                    className="tabItineraireSenseGlobal"
                  >
                    <tbody>
                      <tr>
                        <th width="20%" style={Fonddefaut}>Jour & date </th>
                        <th width="30%" style={Fonddefaut}>Programme</th>
                        <th width="18%" style={Fonddefaut}>Transport</th>
                        <th width="8%" style={Fonddefaut}>Repas</th>
                        <th width="4%" style={Fonddefaut}>Guide</th>
                        <th width="20%" style={Fonddefaut}>Hébergement</th>
                      </tr>
                      {tripAllDays.map((item, index) => {
                        const IncDay = _.sum(TabIncJour.slice(0, index))
                        const datejour = tripDetails[0].date_depart
                          ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                          : ''
                        let guideNeed = false
                        switch (guideArray[index]) {
                          case '0':
                            guideNeed = false
                            break;
                          case '1':
                            guideNeed = true
                            break;
                          case '2':
                            guideNeed = true
                            break;
                          case '3':
                            guideNeed = true
                            break;
                          case '4':
                            guideNeed = true
                            break;
                          default:
                            guideNeed = false
                        }
                        return (
                          <tr key={`Bref${item.id_journee}`}>
                            <td width="20%">
                              <a style={indexDisplay === 'WORD' ? styleTabItiWord : styleTabItiWeb} href={`#Jour${index}`}>
                                J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} to ${IncDay + TabIncJour[index]}`)} {datejour}
                              </a>
                            </td>
                            <td width="30%">
                              <a style={indexDisplay === 'WORD' ? styleTabItiWord : styleTabItiWeb} href={`#Jour${index}`}>
                                {item.nom_journee}
                              </a>
                            </td>
                            <td width="18%">
                              <a style={indexDisplay === 'WORD' ? styleTabItiWord : styleTabItiWeb} href={`#Jour${index}`}>
                                {item.transport}
                              </a>
                            </td>
                            <td width="8%">
                              <a style={indexDisplay === 'WORD' ? styleTabItiWord : styleTabItiWeb} href={`#Jour${index}`}>
                                {tripPension[index].pension !== 'none' && tripPension[index].pension}
                              </a>
                            </td>
                            <td width="4%">
                              <a style={indexDisplay === 'WORD' ? styleTabItiWord : styleTabItiWeb} href={`#Jour${index}`}>
                                {guideNeed ? <span style={check}>✓</span> : ''}
                              </a>
                            </td>
                            <td width="10%">
                              <a style={indexDisplay === 'WORD' ? styleTabItiWord : styleTabItiWeb} href={`#Jour${index}`}>
                                {tripAccomodation[index].nom_hebergement}
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <br />
              <div className="titreSense2" style={Colordefaut}>Description du voyage</div>
              <Interweave content={desc_voyage} />
              <br />
              <div className="CoupePasSense">

                {(tripDetails[0].image || tripDetails[0].map_localisation) && (

                <div className="CoupePasSense">
                  {typeCarte === 1 && tripDetails[0].image
                    && (
                      <div className="centreTabPrice" id="carte">
                        <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                        <br />
                        <img className="carte" style={borderRadius} width="500" height="450" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                        <div className="sautePage" />
                      </div>
                    )}
                    {typeCarte === 2 && tripDetails[0].map_localisation && (
                    <div className="centreTabPrice" id="carte">
                      <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                      <br />
                      <div className="buttonInter">
                        <div id="map" />
                        <label>
                          <input type="checkbox" id="filterLieux" checked />
                          Lieux
                        </label>
                        <label>
                          <input type="checkbox" id="filterHotels" checked />
                          Hôtels
                        </label>
                      </div>
                      <img className="carteGoogleFixe" style={borderRadius} width="500" height="450" src={urlMap} />
                      <div className="sautePage" />
                    </div>

                    )}
                  {typeCarte === 3 && tripDetails[0].map_localisation && (
                  <div className="centreTabPrice" id="carte">
                    <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                    <br />
                    <img style={borderRadius} width="500" height="450" src={urlMap} />
                    <div className="sautePage" />
                  </div>

                  )}
                </div>
                )}
              </div>
              <div id="detail" className="titreSense2" style={Colordefaut}>Programme</div>
              <br />
              <div>
                {tripAllDays.map((item_jour, index) => {
                  const desc = `desc_jour_${index + 1}`;
                  const IncDay = _.sum(TabIncJour.slice(0, index))
                  const imageArrayDay = item_jour.image.split(',')
                  const datejour = tripDetails[0].date_depart
                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                    : ''
                  // eslint-disable-next-line no-nested-ternary
                  let guideNeed = ''
                  switch (guideArray[index]) {
                    case '0':
                      guideNeed = ''
                      break;
                    case '1':
                      guideNeed = langageTranslation.withGuide
                      break;
                    case '2':
                      guideNeed = langageTranslation.withGuideEn
                      break;
                    case '3':
                      guideNeed = langageTranslation.withGuideEnEvasion
                      break;
                    case '4':
                      guideNeed = langageTranslation.withLocal
                      break;
                    default:
                      guideNeed = ''
                  }
                  let driverNeed = ''
                  switch (chauffeurArray[index]) {
                    case '0':
                      driverNeed = ''
                      break;
                    case '1':
                      driverNeed = langageTranslation.driverEvasion
                      break;
                    case '2':
                      driverNeed = ''
                      break;
                    case '3':
                      driverNeed = 'Transport Locaux'
                      break;
                    case '4':
                      driverNeed = langageTranslation.busEvasion
                      break;
                    case '5':
                      driverNeed = langageTranslation.driverSpeakingEnglish
                      break;
                    case '6':
                      driverNeed = langageTranslation.driverSpeakingFrench
                      break;
                    default:
                      driverNeed = ''
                  }
                  const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                  const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver

                  const BandeauJour = () => (
                    <div className="titreJournee">
                      <div id={`Jour${index}`}>  <span style={Colordefaut}>J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} - {datejour}:</span> {item_jour.nom_journee}  </div>
                    </div>
                  );

                  const TableService = () => (
                    <div className="marginDay">
                      {tripPension[index].pension !== 'none' && (<div className="fontAcco">Repas: {tripPension[index].pension}</div>)}
                      {(tripAccomodation && tripAccomodation[index].nom_hebergement !== 'none')
                                                      && (
                                                      <div className="fontAccoColor">
                                                        {tripAccomodationOpt1 && tripAccomodationOpt1[index].nom_hebergement !== 'none' && 'Opt 1: '} Nuit en {tripAccomodation[index].nom_hebergement !== 'none' && (`${tripAccomodation[index].nom_hebergement}${!_.isEmpty(tripAccomodation[index].categorie) ? `(${tripAccomodation[index].categorie})` : ''}`)}
                                                        {nbOfRoomLabel(index) && (!roomsNumberArray ? '' : `, ${nbOfRoomLabel(index)}`)}
                                                      </div>
                                                      )
                          }
                      <div className="fontAcco">{asteriskText}</div>
                      {item_jour.distance && (<div className="fontAcco">{item_jour.distance}</div>)}
                    </div>
                  );
                  return (
                    <div className="centreTabPrice" key={`${datejour}`}>
                      <div>
                        <div>
                          <div>
                            <table
                              width="100%"
                              style={{
                                overflow: 'hidden', tableLayout: 'fixed;',
                              }}
                            >
                              <tr>
                                <td width="100%" className="CoupePasSense">
                                  <BandeauJour />
                                </td>
                              </tr>
                              <tr>
                                <td width="100%" className="CoupePasSense">
                                  <div className="marginDay">
                                    <Interweave content={tripContent[0][desc]} />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td width="100%" className="CoupePasSense">
                                  <div className="paddingService">
                                    <TableService />
                                  </div>
                                </td>
                              </tr>
                            </table>
                            {imageArrayDay[0] !== '' && imageArrayDay.length === 1
                            && (
                              <table
                                style={{
                                  width: '100%',
                                  margin: '0 auto',
                                }}
                              >
                                <tr>
                                  {imageArrayDay.map((item, indexImage) => (
                                    <React.Fragment>
                                      <td width="25%" />
                                      <td style={{ width: '50%' }}>
                                        <img
                                          width="350"
                                          height="250"
                                          className={indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}
                                          src={`${savedImgUrl}/${companyContent.company}/${imageArrayDay[indexImage]}`}
                                          alt={`Imagewe ${indexImage + 1}`}
                                        />
                                      </td>
                                      <td width="25%" />
                                    </React.Fragment>
                                  ))}
                                </tr>
                              </table>
                            )}
                            {imageArrayDay[0] !== '' && imageArrayDay.length === 2
                            && (
                              <table
                                style={{
                                  width: '100%',
                                }}
                              >
                                <tr>
                                  {imageArrayDay.map((item, indexImage) => (
                                    <td style={{
                                      width: '50%',
                                    }}
                                    >
                                      <img width="350" height="250" className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayDay[indexImage]}`} />
                                    </td>
                                  ))}
                                </tr>
                              </table>
                            )}
                            {imageArrayDay[0] !== '' && imageArrayDay.length > 2
                            && (
                              <table
                                style={{
                                  width: '100%',
                                }}
                              >
                                <tr>
                                  {imageArrayDay.map((item, indexImage) => (
                                    <td style={{
                                      width: '33%',
                                    }}
                                    >
                                      <img
                                        width="200"
                                        height="100"
                                        className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`}
                                        src={`${savedImgUrl}/${companyContent.company}/${imageArray[indexImage]}`}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              </table>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <br />
              <div id="prix" className="titreSense2" style={Colordefaut}>Prix du voyage</div>
              <br />
              <div className="CoupePasSense">
                <div>
                  <Interweave content={tripBilling.tabPrice} />
                </div>
                <br />
              </div>
              <div>
                <br />
                <div className="CoupePasSense">
                  <div className="TitrePrixComprend">  {langageTranslation.priceContained}:  </div>
                  <br />
                  <Interweave content={tripDetails[0].prix_comprend} />
                </div>
                <div className="CoupePasSense">
                  <div className="TitrePrixComprendPas">  {langageTranslation.priceDoNotContained}:  </div>
                  <br />
                  <Interweave content={tripDetails[0].prix_comprend_pas} />
                </div>
              </div>
              <br />
              <div id="detail" className="titreSense2" style={Colordefaut}>Entrée des sites</div>
              <br />
              <Interweave content={informationsContent[texteEntreeSite]} />
              <Interweave content={centre_interet} />
              <br />
              <div id="hotel" className="titreSense2" style={Colordefaut}>Hôtels</div>
              <br />
              <Interweave content={informationsContent[texteHotel]} />
              <br />
              <div>
                {parseInt(roomAcco1Array[0], 10) !== 1 && (
                <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 1</div>
                )}
                {
                        Hotel_Unique && Hotel_Unique.map((item, i) => {
                          const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                          const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                          const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');

                          return (
                            <div key={`Acco${item.id_journee}`}>
                              {item.nomHeb !== 'none'
                        && (
                          <div className="CoupePasCiel">
                            <div>
                              <div className="afficheHotelEvasion">
                                <div className="CentreEvasion">
                                  <div className="titreJournee">
                                    <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb}</span> -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                                  </div>
                                  <table
                                    style={{
                                      overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                                    }}
                                  >
                                    <tr>
                                      <td>
                                        <Interweave content={item.desc_hebergement} />
                                        {item.adresse && !_.isEmpty(item.adresse) && <Interweave content={adresseHebClean} />}
                                      </td>
                                    </tr>
                                  </table>
                                  {imageArrayHotel[0] !== ''
                        && (
                          <div>
                            {imageArrayHotel[0] !== '' && imageArrayHotel.length <= 2
        && (
        <table
          style={{
            width: '100%',
          }}
        >
          <tr>
            {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
              <td style={{
                width: '50%',
              }}
              >
                <img width="350" height="250" className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
              </td>
            ))}
          </tr>
        </table>
        )}
                            {imageArrayHotel[0] !== '' && imageArrayHotel.length > 2
        && (
          <table
            style={{
              width: '100%',
            }}
          >
            <tr>
              {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
                <td style={{
                  width: '33%',
                }}
                >
                  <img width="250" height="170" className={`${indexImage === 0 ? 'Image1Jour1CielHotel' : 'Image1Jour1Ciel_plusHotel'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
                </td>
              ))}
            </tr>
          </table>
        )}
                          </div>
                        )}
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        )}
                            </div>
                          )
                        })
                       }
                {hasDifferentValueAcco1 && (
                <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 2</div>
                )}
                {hasDifferentValueAcco1 && (
                  Hotel_Unique && Hotel_Unique.map((item, i) => {
                    const imageArrayHotel = item.image_hebergement1 ? item.image_hebergement1.split(',') : ''
                    const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                    const adresseHebClean = item.adresse1.replace(/<p>/g, '').replace(/<\/p>/g, '');

                    return (
                      <div key={`Acco${item.id_journee}`}>
                        {item.nomHeb1 !== 'none'
                      && (
                        <div className="CoupePasCiel">
                          <div>
                            <div className="afficheHotelEvasion">
                              <div className="CentreEvasion">
                                <div className="titreJournee">
                                  <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb1}</span> -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                                </div>
                                <table
                                  style={{
                                    overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                                  }}
                                >
                                  <tr>
                                    <td>
                                      <Interweave content={item.desc_hebergement1} />
                                      {item.adresse1 && !_.isEmpty(item.adresse1) && <Interweave content={adresseHebClean} />}
                                    </td>
                                  </tr>
                                </table>
                                {imageArrayHotel[0] !== ''
                      && (

                        <div>
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length <= 2
      && (
        <table
          style={{
            width: '100%',
          }}
        >
          <tr>
            {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
              <td style={{
                width: '50%',
              }}
              >
                <img width="350" height="250" className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
              </td>
            ))}
          </tr>
        </table>
      )}
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length > 2
      && (
        <table
          style={{
            width: '100%',
          }}
        >
          <tr>
            {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
              <td style={{
                width: '33%',
              }}
              >
                <img width="250" height="170" className={`${indexImage === 0 ? 'Image1Jour1CielHotel' : 'Image1Jour1Ciel_plusHotel'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
              </td>
            ))}
          </tr>
        </table>
      )}
                        </div>
                      )}
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      )}
                      </div>
                    )
                  })
                )}
                {hasDifferentValueAcco2 && (
                <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 3</div>
                )}
                {hasDifferentValueAcco2 && (
                  Hotel_Unique && Hotel_Unique.map((item, i) => {
                    const imageArrayHotel = item.image_hebergement2 ? item.image_hebergement2.split(',') : ''
                    const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                    const adresseHebClean = item.adresse2.replace(/<p>/g, '').replace(/<\/p>/g, '');

                    return (
                      <div key={`Acco${item.id_journee}`}>
                        {item.nomHeb2 !== 'none'
                      && (
                        <div className="CoupePasCiel">
                          <div>
                            <div className="afficheHotelEvasion">
                              <div className="CentreEvasion">
                                <div className="titreJournee">
                                  <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb2}</span> -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                                </div>
                                <table
                                  style={{
                                    overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                                  }}
                                >
                                  <tr>
                                    <td>
                                      <Interweave content={item.desc_hebergement2} />
                                      {item.adresse2 && !_.isEmpty(item.adresse2) && <Interweave content={adresseHebClean} />}
                                    </td>
                                  </tr>
                                </table>
                                {imageArrayHotel[0] !== ''
                      && (
                        <div>
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length <= 2
      && (
        <table
          style={{
            width: '100%',
          }}
        >
          <tr>
            {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
              <td style={{
                width: '50%',
              }}
              >
                <img className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
              </td>
            ))}
          </tr>
        </table>
      )}
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length > 2
      && (
        <table
          style={{
            width: '100%',
          }}
        >
          <tr>
            {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
              <td style={{
                width: '33%',
              }}
              >
                <img className={`${indexImage === 0 ? 'Image1Jour1CielHotel' : 'Image1Jour1Ciel_plusHotel'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
              </td>
            ))}
          </tr>
        </table>
      )}
                        </div>
                      )}
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      )}
                      </div>
                    )
                  })
                ) }

              </div>
              <Grid container spacing={0} justifyContent="center">
                <Grid item xs={12}>
                  <div id="info" className="centreTabPrice">
                    <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
                  </div>
                  <div className="centreTabPrice">
                    <img
                      src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/cielyunnan/license-1689587893848.jpg"
                    />
                  </div>
                </Grid>
              </Grid>

              {(indexDisplay !== 'HTMLWORD') && (
              <div id="video" className="videoBoxSense">
                <div className="titreSense2" style={Colordefaut}>VIDEO</div>
                <iframe className="VideoSense" width="624" height="468" src="https://www.youtube.com/embed/RfJ_3vYVrpY" title="Ciel Chine" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
              )}
            </div>
          </div>
        </div>

      )
      return myPage
    }
    const pageHtml = renderToStaticMarkup(myPageFunc('HTMLWEB'));
    let tripAcco = []
    switch (tripBilling.accoChoice) {
      case '1':
        tripAcco = tripAccomodation
        break;
      case '2':
        tripAcco = tripAccomodationOpt1
        break;
      case '3':
        tripAcco = tripAccomodationOpt2
        break;
      default:
        tripAcco = tripAccomodation
    }
    const urlBel = _.isEmpty(urlDevis)
      ? tripDetails[0].urlDevis.replace(/https:\/\/duyendoc\.s3(\.ap-south-1)?\.amazonaws\.com/, 'https://resa.cielchine.com')
      : urlDevis.replace(/https:\/\/duyendoc\.s3(\.ap-south-1)?\.amazonaws\.com/, 'https://resa.cielchine.com');
    let toto = 'markersLieux =  ['
    let toto2 = 'markersHotels = [];'

    let premierePosition = ''
    // const path = '';
    if (tabMap && !_.isEmpty(tabMap[0])) {
      tabMap.forEach((tM, index) => {
        // Ajoute les marqueurs à 'toto'
        toto += `          
          new google.maps.Marker({position: { lat: ${tM[0].latitude}, lng: ${tM[0].longitude} },
            map: map,
            title: "${tM[0].lieu}",
            icon: {
                      url: "${this.createCustomMarkerIcon(index + 1)}", // URL de l'icône
                      scaledSize: new google.maps.Size(32, 32), // Taille de l'icône
                    },
          }),`;

        // Ajoute les positions à 'path'
        // path += `{ lat: ${tM[0].latitude}, lng: ${tM[0].longitude} },`
      });
      toto += ']'
      tripAcco.forEach((tA) => {
        if (tA.latitudeAcco && tA.longitudeAcco) {
          toto2 += `
            (function() {
                const marker = new google.maps.Marker({
                    position: { lat: ${tA.latitudeAcco}, lng: ${tA.longitudeAcco} },
                   label: {
                    text: "H", // Le texte que vous voulez afficher
                    color: "white", // Couleur du texte
                    fontWeight: "bold",
                    fontSize: "12px"
                },   
                    map: map,
                    title: "${tA.nom_hebergement}"
                });
    
                google.maps.event.addListener(marker, 'click', function() {
                    const googleMapsUrl = "https://www.google.com/maps/dir/?api=1&destination=${tA.latitudeAcco},${tA.longitudeAcco}&travelmode=driving";
                    window.open(googleMapsUrl, '_blank');
                });
    
                markersHotels.push(marker);
            })();
            `;
        }
      });
      // Définir la première position
      premierePosition = `{ lat: ${tabMap[0][0].latitude}, lng: ${tabMap[0][0].longitude} },`
    }
    // const polyploy = `
    //  const polyline = new google.maps.Polyline({
    //    path:  [
    //      ${path}
    //   ],
    //   geodesic: true,
    //     strokeColor: "#FF0000",
    //    strokeOpacity: 1.0,
    //   strokeWeight: 2,
    // });
    //   `

    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Votre proposition de voyage</title>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet">
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
      <script>
        (g => {
          var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window;
          b = b[c] || (b[c] = {});
          var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => {
            await (a = m.createElement("script"));
            e.set("libraries", [...r] + "");
            for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]);
            e.set("callback", c + ".maps." + q);
            a.src = "https://maps.googleapis.com/maps/api/js?" + e;  // Correction ici
            d[q] = f;
            a.onerror = () => h = n(Error(p + " could not load."));
            a.nonce = m.querySelector("script[nonce]")?.nonce || "";
            m.head.append(a);
          }));
          d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n));
        })({
          key: "AIzaSyCUHs6GMO6QKYfSHFsslBpEHJH7Ue-xfdY",
          v: "weekly"
        });
      </script>
     <style>    
    
  
    .centreTabPrice table {
      break-inside: avoid;
    }
  
    #map {
  height: 600px;
  width: 100%;
    }

    .buttonInter label {
  margin-right: 15px; /* Espace entre les boutons */
  display: inline-block; /* Pour garder les éléments en ligne */
}

     @media print {
  #map {
     display:none;
    }   

     .buttonInter {
     display:none;
    }   
}

      </style>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCielPro.css">
    
     </head>
     <body>
     <script>
     function toggleMenu() {
      var navBarCiel = document.querySelector(".navBarCiel");
      var iconMenu = document.querySelector(".iconMenu");
      navBarCiel.classList.toggle("responsive");
      iconMenu.classList.toggle("responsive");
    }
          document.addEventListener('DOMContentLoaded', function() {
             document.querySelector('.btn-position').addEventListener('click', function() {
                     var espaceCarroussel = document.querySelector('.espaceCarroussel');
                     var pageheadersensehtml = document.querySelector('.pageheadersensehtml');
                     var GlobalFileSense= document.querySelector('.GlobalFileSense');
                     espaceCarroussel.classList.toggle("responsive");
                     GlobalFileSense.classList.toggle("responsive");
                     pageheadersensehtml.classList.toggle("responsive");
                  }) 
                  });

                  document.addEventListener('DOMContentLoaded', function() {
             document.querySelector('.close-galerie').addEventListener('click', function() {
                     var espaceCarroussel = document.querySelector('.espaceCarroussel');
                     var pageheadersensehtml = document.querySelector('.pageheadersensehtml');
                     var GlobalFileSense= document.querySelector('.GlobalFileSense');
                     espaceCarroussel.classList.toggle("responsive");
                     GlobalFileSense.classList.toggle("responsive");
                     pageheadersensehtml.classList.toggle("responsive");
                   }) 
                  });

  
    </script>
     <div class="pageheadersensehtml">   
       <div class="MuiGrid-root MuiGrid-container">
         <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-3" style="text-align: center; padding: 10px; display: flex; align-items: center; border: 2px solid ${informationsContent.main_color};">
           <img src="${savedImgUrl}/${companyContent.company}/${companyContent.logo}" style="max-width: 100%; height: 60px; display: inline-block; margin: auto;">
       </div>
         <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7" style="border: 2px solid ${informationsContent.main_color};">
              <div class="centreSense padded">           
             ${travelerName ? `<div class="name">${travelerName}</div>` : ''}
               <div class="tour-code" style="font-weight: 500;">Code devis: ${code_devis}</div>
               <div class="date-range" style="font-weight: 500;">Du ${dateDepart} au ${dateFin}</div>
              </div>
         </div>
         <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2" style="border: 2px solid ${informationsContent.main_color};">
           <div style="padding: 10px; display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
           <div class="iconMenu" onclick="toggleMenu()">
           <div style="background-color: ${informationsContent.main_color};" class="bar1"></div>
           <div style="background-color: ${informationsContent.main_color};" class="bar2"></div>
           <div style="background-color: ${informationsContent.main_color};" class="bar3"></div>
            </div>
           <a href="#" class="affiche-print" OnClick="javascript:window.print()">
           <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/cielyunnan/icons8-download-1704263805625.png" alt="Download Icon" style="width: 50px; height: 50px;">
               <div style="font-weight: bold; font-size: 10px; color: ${informationsContent.main_color}">Télécharger</div>
           </a> 
           </div>
       </div>           
       <div class="navBarCiel" style="background-color: ${informationsContent.main_color};">
         <a href="#home" class="animation-button" onclick="toggleMenu()">Home</a>
     <a href="#brief" class="animation-button" onclick="toggleMenu()">Itineraire</a>
     <a href="#carte" class="animation-button" onclick="toggleMenu()">Carte</a>
     <a href="#hotel" class="animation-button" onclick="toggleMenu()">Hébergement</a>
     <a href="#prix" class="animation-button" onclick="toggleMenu()">Prix</a>
     <a href="#detail" class="animation-button" onclick="toggleMenu()">Programme détaillé</a>
     <a href="#info" class="animation-button" onclick="toggleMenu()">Bon à savoir</a>
     <a href="#video" class="animation-button" onclick="toggleMenu()">Video</a>    
         </div>
         </div>
      </div>    
        ${devisContent}
        <script>
          let map;

async function initMap() {
  const { Map } = await google.maps.importLibrary("maps");
 map = new Map(document.getElementById("map"), {
    zoom: 4,
    center: ${premierePosition}
    mapId: "DEMO_MAP_ID",
    region: "CN",
  });

  ${toto};

  ${toto2};

  
}

// Fonction pour afficher ou masquer les marqueurs en fonction de l'état des cases à cocher
function updateMarkers() {
  const showLieux = document.getElementById("filterLieux").checked;
  const showHotels = document.getElementById("filterHotels").checked;

  markersLieux.forEach(marker => marker.setMap(showLieux ? map : null));
  
  // Si vous avez des marqueurs d'hôtel, activez cette ligne pour gérer leur affichage
  markersHotels.forEach(marker => marker.setMap(showHotels ? map : null));
}

// Ajout des écouteurs d'événements pour les cases à cocher
document.getElementById("filterLieux").addEventListener("change", updateMarkers);
document.getElementById("filterHotels").addEventListener("change", updateMarkers);


initMap();
</script>
      </body>
   </html>
   `;

    return (
      <>
        {isLoading ? <Loader /> : null}
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
            <Grid item md={12} xs={12}>

              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    style={{ flexDirection: 'row' }}
                    value={typeCarte}
                    onChange={event => this.setState({
                      typeCarte: Number(event.target.value),
                      devisContent: '',
                    })}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="No map" />
                    <FormControlLabel value={1} control={<Radio />} label="Image" />
                    <FormControlLabel value={2} control={<Radio />} label="Interactive with google map" />
                    <FormControlLabel value={3} control={<Radio />} label="Fix with google map" />
                  </RadioGroup>
                </FormControl>
              </div>

            </Grid>
            <Grid item xs={12}>
              <div className="editorStyle">
                <p className="editorTitle background-details">Description</p>
                <TextEditor
                  linkCss={[
                    'https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCielPro.css',
                  ]}
                  height={1000}
                  value={_.isEmpty(devisContent) ? pageHtml : devisContent}
                  handleEditorChange={content => this.handleEditorChange(content)}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  accomodationList: state.api.accomodationList,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripRoom: state.api.tripRoom,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripDevisFinal: state.api.tripDevisFinal,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlCielPro))
