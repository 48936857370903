import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImageUploader from 'react-images-upload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { red } from '@material-ui/core/colors';
import _ from 'lodash'
import axios from 'axios'
import stage from '../../config'
import {
  CssGrid,
} from '../../configCSS'

const apiUrl = stage.api.duyen.url
// const savedImgUrl = stage.imgStorage

class Uploader extends React.Component {
  static propTypes = {
    imageDataArray: PropTypes.array,
    saveImage: PropTypes.func,
    deleteImage: PropTypes.func,
    redirectToGallery: PropTypes.func,
    allowDrop: PropTypes.bool,
    nbMaxImg: PropTypes.number,
    selectFromDatabase: PropTypes.bool,
    companyContent: PropTypes.object,
    field: PropTypes.string,
    inLine: PropTypes.string,
    GET_COMPANY_CONTENT: PropTypes.func.isRequired,
    GET_PICTURE: PropTypes.func.isRequired,
  }

  static defaultProps = {
    companyContent: {},
    imageDataArray: [],
    allowDrop: false,
    nbMaxImg: 1,
    selectFromDatabase: true,
    field: '',
    inLine: null,
    saveImage: () => { },
    deleteImage: () => { },
    redirectToGallery: () => { },
  }

  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem('userId'),
      imageUrlArray: [],
      companyContent: {},
    };
    this.onDrop = this.onDrop.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.displayPictures = this.displayPictures.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      imageDataArray,
      companyContent,
      GET_COMPANY_CONTENT,
    } = this.props
    this.setState({
      imageUrlArray: imageDataArray[0],
      imageNameArray: imageDataArray[1],
      companyContent,
    })
    if (_.isEmpty(companyContent)) {
      GET_COMPANY_CONTENT(userId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      companyContent: companyContentOld,
    } = this.state
    const {
      imageDataArray: imageDataArrayOld,
    } = this.props
    const {
      imageDataArray,
      companyContent,
    } = nextProps
    if (!_.isEqual(imageDataArray, imageDataArrayOld)) {
      this.setState({
        imageUrlArray: imageDataArray[0],
        imageNameArray: imageDataArray[1],
      })
    }
    if (!_.isEqual(companyContent, companyContentOld)) {
      this.setState({
        companyContent,
      })
    }
  }

  onDrop(pictureFiles) {
    const {
      userId,
      companyContent,
    } = this.state
    const {
      saveImage,
      allowDrop,
      nbMaxImg,
      imageDataArray,
      field,
      GET_PICTURE,
    } = this.props

    if (!allowDrop) return null

    // get image file data

    // limit to 3 pictures to upload
    const imageFile = _.takeRight(pictureFiles, nbMaxImg)

    // Upload ****
    const dropedImagesDataArray = imageFile.map((file) => {
      const { company } = companyContent
      // name format : name-date.extension
      const imageName = `${_.split(file.name, '.', 1)}-${Date.now()}.${_.split(file.name, '.')[1]}`
      // upload image file to API server
      const imageFormObj = new FormData()
      imageFormObj.append('imageName', imageName)
      imageFormObj.append('company', company.toLowerCase())
      imageFormObj.append('imageData', file)
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': 'multipart-form-data',
        },
      }
      // send image to server and get all pictures again for Gallery
      axios.post(`${apiUrl}/api/upload`, imageFormObj, config)
        .then((response) => {
          if (response.data.uploadSuccess) {
            GET_PICTURE(userId, company)
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // send tmp image url to component
      const newImageUrl = URL.createObjectURL(file)

      return {
        imageUrl: newImageUrl,
        imageName,
      }
    })

    // transform imageDataArray from format [[urls], [names]] to [{url, name}, {}, {}..]
    let newImagesDataArray = _.isEmpty(imageDataArray[1]) || nbMaxImg === 1
      ? []
      : imageDataArray[0].map((url, i) => (
        {
          imageUrl: url,
          imageName: imageDataArray[1][i],
        }
      ))

    dropedImagesDataArray.map((img) => {
      newImagesDataArray.push(img)
      return null
    })

    // limit total pictures to number max autorized
    newImagesDataArray = _.takeRight(newImagesDataArray, nbMaxImg)

    // save new image array
    saveImage(newImagesDataArray, field)

    // keep images displayed
    this.setState({
      imageUrlArray: newImagesDataArray.map(url => url.imageUrl),
    });
    return null
  }

  onDelete = (url) => {
    const {
      imageUrlArray,
      imageNameArray,
    } = this.state
    const {
      deleteImage,
    } = this.props

    // remove image to delete
    const indexUrl = imageUrlArray.indexOf(url)
    const deletedImageUrlArray = _.clone(imageUrlArray)
    const deletedImageNameArray = _.clone(imageNameArray)
    deletedImageUrlArray.splice(indexUrl, 1)
    deletedImageNameArray.splice(indexUrl, 1)

    this.setState({
      imageUrlArray: deletedImageUrlArray,
      imageNameArray: deletedImageNameArray,
    })

    deleteImage(deletedImageUrlArray, deletedImageNameArray)
  }

  redirectToGallery = () => {
    const {
      redirectToGallery,
    } = this.props
    redirectToGallery()
  }

  displayPictures = (url, name, i = 1) => {
    const {
      imageUrlArray,
    } = this.state
    const {
      allowDrop,
      inLine,
    } = this.props
    const disabledDeleteBtn = (_.isEmpty(imageUrlArray) || imageUrlArray[0].indexOf('default') === -1) && allowDrop === true ? null : 'disabled'
    return (
      <CssGrid
        item
        md={inLine ? 3 : 12}
        xs={12}
        key={`${url}${i + 1}`}
        className="img-group"
      >
        <div className="cart-picture">
          <img src={url || `${apiUrl}/images/default.png`} />
          {name && (
            <p>{name.slice(0, -18)}</p>
          )}
          <HighlightOffIcon
            style={{ color: red[500] }}
            className={`delete-image-icon ${disabledDeleteBtn}`}
            onClick={() => this.onDelete(url)}
          />
        </div>
      </CssGrid>
    )
  }

  render() {
    const {
      imageUrlArray,
      imageNameArray,
    } = this.state
    const { nbMaxImg } = this.props
    const {
      allowDrop,
      selectFromDatabase,
      inLine,
    } = this.props
    const disabledClassBtnDb = allowDrop ? null : 'disabled'
    const disabledClassBtnComp = allowDrop && allowDrop !== 'dayLine' ? null : 'disabled'
    return (
      <div className="uploader">
        <CssGrid
          container
          spacing={1}
          className="image-collection"
          style={{ display: 'flex', justifyContent: 'start' }}
        >
          {imageUrlArray && imageNameArray && (
            nbMaxImg === 1
              ? this.displayPictures(imageUrlArray[0], imageNameArray[0])
              : imageUrlArray.map((url, i) => this.displayPictures(url, imageNameArray[i], i))
          )}
        </CssGrid>
        <div style={inLine ? { height: '20px' } : null} />
        <div className={`buttons-collection ${inLine}`}>
          <ImageUploader
            name="image"
            withIcon={false}
            buttonText="Select from computer"
            buttonClassName={`buttonStyle ${disabledClassBtnComp}`}
            onChange={this.onDrop}
            singleImage
            imgExtension={
              ['.jpg', 'jpeg', '.gif', '.png']
            }
            maxFileSize={524288}
            label="Max file size: 500Kb, accepted: jpg, jpeg, gif, png"
            className="images-uploader"
          />
          {selectFromDatabase && (
            <button
              type="submit"
              className={`select-db-button ${disabledClassBtnDb}`}
              onClick={() => this.redirectToGallery()}
            >
              Select from database
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
})

const mapDispatchToProps = dispatch => ({
  GET_COMPANY_CONTENT: userId => dispatch({
    type: 'GET_COMPANY_CONTENT',
    userId,
  }),
  GET_PICTURE: (userId, company) => dispatch({
    type: 'GET_PICTURE',
    userId,
    company,
  }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Uploader)
