/* eslint-disable prefer-promise-reject-errors */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Editor } from '@tinymce/tinymce-react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import axios from 'axios';
import stage from '../../config'


const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage

class TextEditor extends PureComponent {
  static propTypes = {
    object: PropTypes.object,
    value: PropTypes.string,
    linkCss: PropTypes.string,
    height: PropTypes.number,
    disabled: PropTypes.bool,
    handleEditorChange: PropTypes.func,
    companyContent: PropTypes.object,
  }

  static defaultProps = {
    object: {},
    linkCss: '',
    value: '',
    height: 250,
    companyContent: {},
    disabled: false,
    handleEditorChange: () => {},
  }

  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleImageUpload = this.handleImageUpload.bind(this)
    this.handleImageUploadError = this.handleImageUploadError.bind(this)
    this.state = {
      object: {},
      value: '',
    };
  }

  componentDidMount() {
    const {
      object,
      value,
    } = this.props
    this.setState({
      object,
      value,
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      object: objectOld,
      value: valueOld,
    } = this.state
    const {
      object,
      value,
    } = nextProps
    if (value !== valueOld || object !== objectOld) {
      this.setState({
        object,
        value,
      })
    }
  }

  componentWillUnmount() {
  }


  handleEditorChange = (content) => {
    const {
      handleEditorChange,
    } = this.props
    handleEditorChange(content)
  }

  handleImageUploadError = (error, message) => {
    console.error('Image upload failed:', error);
    alert(`Failed to upload image: ${message}`);
  };

  handleImageUpload = blobInfo => new Promise((resolve, reject) => {
    const { companyContent } = this.props;
    const { company } = companyContent;
    const imageName = `${_.split(blobInfo.blob().name, '.', 1)}-${Date.now()}.${_.split(blobInfo.blob().name, '.')[1]}`;
    const imageFormObj = new FormData();
    imageFormObj.append('imageName', imageName);
    imageFormObj.append('company', company.toLowerCase());
    imageFormObj.append('imageData', blobInfo.blob());

    if (blobInfo.blob().size > 524288) {
      reject('Image size exceeds the limit of 500 KB');
      return;
    }

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
      },
    };

    axios.post(`${apiUrl}/api/upload`, imageFormObj, config)
      .then((response) => {
        if (response.data.uploadSuccess) {
          const imageUrl = `${savedImgUrl}/${response.data.imageUrl}`;
          const imageTitle = blobInfo.filename();
          resolve(imageUrl, { title: imageTitle });
        } else {
          reject('Failed to upload image');
        }
      })
      .catch((error) => {
        console.error('Failed to upload image', error);
        reject('Failed to upload image');
      });
  });


  render() {
    const { value } = this.state
    const {
      disabled,
      height,
      linkCss,
    } = this.props


    return (
      <div className="custom-editor-container">
        <Editor
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          value={value || ''}
          disabled={disabled}
          init={{
            selector: '.custom-editor-container',
            license_key: 'gpl',
            height: height,
            menubar: false,
            plugins: 'lists link emoticons table image code pagebreak',
            toolbar: 'pagebreak | undo redo | bold | italic | underline | forecolor | backcolor | fontfamily | fontsize | link | alignleft aligncenter alignright alignjustify | lineheight | numlist bullist | emoticons | table | image | code',

            ...(linkCss && { content_css: linkCss }), // Conditionnellement inclure content_css
            font_family_formats: `
          Andale Mono=andale mono,times;
          Arial=arial,helvetica,sans-serif;
          Arial Black=arial black,avant garde;
          Averta Demo=Averta Demo;
          Averta Demo ExtraBold Italic=Averta-Demo-ExtraBold-Italic;  
          Book Antiqua=book antiqua,palatino;
          Cambria=cambria,sans-serif;
          Calibri=calibri;
          Comic Sans MS=comic sans ms,sans-serif;
          Courier New=courier new,courier;
          Georgia=georgia,palatino;
          Gill Sans MT=Gill Sans MT,sans-serif;    
          Helvetica=helvetica;
          Impact=impact,chicago;
          Lato=Lato;
          Montserrat=Montserrat;
          Myriad Arabic Regular=myriad-arabic;
          Noto Sans=Noto Sans;
          Open Sans=Open Sans;
          Poppins=poppins;
          Raleway=raleway;
          Roboto=roboto;
          Symbol=symbol;
          Tahoma=tahoma,arial,helvetica,sans-serif;
          Terminal=terminal,monaco;
          Times New Roman=times new roman,times;
          Trebuchet MS=trebuchet ms,geneva;
          Verdana=verdana,geneva;
          Webdings=webdings;
          Wingdings=wingdings,zapf dingbats;
        `,

            contextmenu: false,
            image_advtab: true,
            default_link_target: '_blank',
            pagebreak_separator: ' <div style="page-break-after: always; clear:both"/></div>',
            line_height_formats: '8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 36px',
            font_size_formats: '8pt 8.5pt 9pt 9.5pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 24pt',
            images_upload_handler: this.handleImageUpload,
            images_upload_error_handler: this.handleImageUploadError,
            table_default_attributes: {
              border: '1px',
            },
          }}
          onEditorChange={this.handleEditorChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
})

const mapDispatchToProps = dispatch => ({
  GET_PICTURE: (userId, company) => dispatch({
    type: 'GET_PICTURE',
    userId,
    company,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextEditor))
